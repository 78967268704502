.prompts {
  padding: 0 30px;
  flex-direction: column;
  align-items: center;
  display: flex;
  &Label {
    margin-bottom: 15px;
  }
  &Subheader{
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 40px;
    letter-spacing: 0.04em;
    color: #595959;
    margin-bottom: 25px;
    margin-top: 0;
    text-align: center;
  }
  &Labels {
    margin-bottom: 25px;
    //&::-webkit-scrollbar {
    //  background: none;
    //  width: 0;
    //  display: none;
    //}
    overflow-y: auto;
    max-height: calc(100vh - 332px);
  }
}
