.navigation {
  display: flex;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 2;
  &Button {
    width: fit-content;
    padding: 0 10px;
    height: 60px;
  }
}

.btnWrapper {
  display: grid;
  gap: 20px;
  grid-auto-flow: column;
}

.title {
  font-weight: 500;
  font-size: 28px;
  text-transform: uppercase;
  margin: 0;
  margin-top: 25px;
}

.selectedGuestsNumber {
  font-size: 20px;
  color: #b6b5b5;
  margin: 0;
  font-weight: 500;
  margin-top: 5px;
  text-align: center;
}

.title {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 37px;
  text-align: center;
  letter-spacing: 0.06em;
  color: #000000;
}

.assignAcitivitiesHeaderWrapper {
  position: relative;
}

.cardTitleWrapper {
  position: absolute;
  top: 1em;
  right: 1em;
}
.iconButtonText {
  display: block;
}

.navigationButton {
  width: 200px;
  height: 60px;
}

@media (min-width: 1200px) {
  .navigationButton {
    width: 360px;
    height: 90px;
  }
}

@media (max-width: 800px) {
  .iconButtonText {
    display: none;
  }
  .addGuestButton {
    height: 55px;
    width: 55px;
    font-size: 3.5em;
  }
}
