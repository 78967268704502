.memberCard {
  position: relative;
}
.memberCardActive {
  > button {
    border: 4px solid #df151c;
  }
}
.memberCardInner {
  width: 100%;
  height: 100%;
  padding-top: 60px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: #ffffff;
  box-shadow: 0px 15px 60px rgba(26, 20, 63, 0.1);
  cursor: pointer;
  border: 4px solid transparent;
}
.memberCardName {
  width: 100%;
  padding: 0 15px;
  margin: 0 0 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  letter-spacing: 0.08em;
  color: #000000;
}
.memberCardBirthday {
  margin: 0;
  font-style: normal;
  font-weight: normal;
  text-align: center;
  letter-spacing: 0.06em;
  color: #959595;
}
.memberCardGender {
  position: absolute;
  top: 16px;
  right: 20px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  text-align: right;
  letter-spacing: 0.14em;
  text-transform: uppercase;
  color: #959595;
}
.removeButton {
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: 1;
  button {
    display: flex;
    width: 60px;
    height: 60px;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    background-color: #ececec;
    border: none;
    touch-action: manipulation;
    &:focus {
      outline: none;
    }
    &:hover {
      cursor: pointer;
    }
  }
}
.timeSlotsList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  // padding: 20px 0 100px;
}
@media (min-width: 768px) {
  .memberCard {
    min-width: 225px;
    padding: 15px;
    // height: 350px;
  }
}
@media (min-width: 1024px) {
  .memberCard {
    min-width: 200px;
    padding: 0px 0px 10px 10px;
  }
}
@media (min-width: 1200px) {
  .memberCard {
    min-width: 250px;
    padding: 20px;
  }
}
