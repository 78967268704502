.container {
  display: flex;
  height: 100%;
  width: 100%;
}
.button {
  flex: 1;
  border-radius: 0;
  &AddGuest {
    // background-color: #CF131A !important;
  }
  &FinishCheckIn {
    // background-color: #DF151C !important;
  }
  &ManageAccounts {
    // background-color: #CF131A !important;
  }
}
