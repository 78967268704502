.wizardFormField {
  display: flex;
  height: 100%;
  flex-direction: column;
  background-color: #f2f2f2;
}
.mainContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.wizardFormFieldInner {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (min-width: 768px) {
  .container {
    flex-direction: column;
  }
  .separator {
    margin: 15px 0;
  }
  .title {
    font-size: 24px;
    line-height: 30px;
  }
  .rowMargin {
    margin-bottom: 20px;
  }
  .navigationButton {
    width: 240px;
    height: 60px;
  }
}
@media (min-width: 1024px) {
  .container {
    flex-direction: row;
  }
  .separator {
    margin: 25px 0;
  }
  .title {
    font-size: 40px;
    line-height: 47px;
  }
  .rowMargin {
    margin-bottom: 30px;
  }
  .logoContent {
    margin: 5px 0px 0px 0px;
  }
}
@media (min-width: 1200px) {
  .navigationButton {
    width: 360px;
    height: 90px;
  }
}
