.waiverAgreementText {
  overflow: hidden;
  padding: 5px;
  overflow-y: auto;
  border-radius: 5px;
  border: 1px solid rgb(217, 217, 217);
  box-shadow: 0 15px 60px rgba(26, 20, 63, 0.1);
  max-height: 30vh;
  > div {
    padding: 10px;
    color: #595959;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.04em;
  }
  &Portrait {
    max-height: 50vh;
    height: auto;
    min-height: 200px;
    @media screen and (min-height: 1550px) {
      max-height: 60vh;
    }
  }
  &Double {
    max-height: 20vh;
    @media screen and (min-height: 1550px) {
      max-height: 28vh;
    }
  }
}
