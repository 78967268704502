.idleMonitor {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
}
.content {
  flex: 1;
}
.footer {
  display: flex;
  flex: 1 1;
}
.title {
  margin: 65px 0 25px;
  font-weight: 500;
  font-size: 32px;
  line-height: 37px;
  text-align: center;
  letter-spacing: 0.06em;
  color: #000000;
}
.subTitle {
  margin: 0 0 65px;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #000000;
}
.countdown {
  display: flex;
  margin: 0 0 70px;
  justify-content: center;
  align-items: center;
}
.button {
  display: flex;
  flex: 1;
}

@media (min-width: 768px) {
  //.idleMonitor {
  //  width: 70%;
  //}
  .button {
    height: 60px;
  }
}
@media (min-width: 1024px) {
  //.idleMonitor {
  //  width: 50%;
  //}
}
@media (min-width: 1200px) {
  .idleMonitor {
    width: 600px;
  }
  .button {
    height: 90px;
  }
}
