.container {
  display: flex;
  width: 600px;
  flex-direction: column;
  background: #ffffff;
}
.title {
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 37px;
  text-align: center;
  letter-spacing: 0.06em;
  color: #000000;
}
.description {
  p {
    margin: 0 0 20px;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 40px;
    text-align: center;
    letter-spacing: 0.04em;

    color: #000000;
  }
}
.footer {
  display: flex;
}
.button {
  width: 100%;
  height: 80px;
  font-size: 16px;
  line-height: 19px;
}
