.cartItemRemoveAlert {
  max-width: 100%;
  width: 31.250vw;
  background: #fff;
  &__content {
    text-align: center;
    padding: 0 15px;
    p, h2 {
      margin: 0;
    }
    h2 {
      font-family: Roboto, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 1.667vw;
      line-height: 2.188vw;
      text-align: center;
      letter-spacing: 0.06em;
      margin-bottom: 8px;
      padding-top: 2.604vw;
    }
    p {
      font-family: Roboto, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 1.146vw;
      line-height: 2.083vw;
      text-align: center;
      letter-spacing: 0.04em;
      margin-bottom: 8px;
    }
  }
  &__navigation {
    margin-top: 2.083vw;
    display: flex;
    justify-content: space-between;
    & > button {
      flex:1 1 50%;
      width: 100%;
      height: 4.167vw;
      font-family: Roboto, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 0.833vw;
      line-height: 0.990vw;
      text-align: center;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      color: #fff;
    }
    & > button.whiteButton {
      background: #F2F2F2;
      color: #000000;
    }
  }
}
