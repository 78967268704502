.contentInner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
}
.title {
  margin-top: 0;
  margin-bottom: 5px;
  font-style: normal;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0.08em;
  color: #ffffff;
}
.fieldLabel {
  margin: 0 0 5px;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.04em;
  color: rgba(255, 255, 255, 0.8);
}
.fieldWithDescription {
  position: relative;
}
.fieldDescription {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-align: right;
  letter-spacing: 0.06em;
  color: rgba(255, 255, 255, 0.6);
}
.fieldDescriptionLeft {
  position: absolute;
  justify-content: flex-end;
  top: 0;
  left: -215px;
  width: 200px;
  bottom: 0;
}
.smallField {
  width: 100%;
}
.fieldDescriptionRight {
  position: absolute;
  top: 0;
  right: -215px;
  width: 200px;
  bottom: 0;
}
.navigation {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
}
.clover {
  position: relative;
  display: flex;
}
.cloverButton {
  position: absolute;
  padding: 0 10px;
  white-space: nowrap;
}
.cancelButton {
  position: absolute;
  bottom: 0;
  left: 0;
}
.saveButton {
  position: absolute;
  bottom: 0;
  right: 0;
}
.cloverPassModal {
  width: 400px;
  padding: 20px;
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 40px;
    letter-spacing: 0.04em;
    text-align: center;
  }
}
.cloverPass {
  font-weight: bold;
  text-align: center;
  font-size: 40px;
  line-height: 45px;
  letter-spacing: 0.04em;
}
.cloverPassButtonWrap {
  display: flex;
  align-items: center;
  justify-content: center;
}
.cloverPassButton {
  width: 360px;
  height: 90px;
}
@media (min-width: 768px) {
  .contentInner {
  }
  .title {
    font-size: 20px;
    line-height: 27px;
  }
  .fieldLabel {
    font-size: 14px;
    line-height: 16px;
  }
  .fieldDescription {
    font-size: 14px;
  }
  .cloverButton {
    height: 45px;
    top: 60px;
    left: 0;
  }
  .cancelButton {
    width: 240px;
    height: 60px;
  }
  .saveButton {
    width: 240px;
    height: 60px;
  }
  .spaceBetweenRow {
    margin-bottom: 20px;
  }
}
@media (min-width: 1024px) {
  .contentInner {
  }
  .title {
    font-size: 20px;
    line-height: 27px;
  }
  .smallField {
    > input {
      min-height: 35px;
    }
  }
  .cloverButton {
    height: 35px;
    top: 0px;
    left: 215px;
  }
  .spaceBetweenRow {
    margin-bottom: 10px;
  }
}
@media (min-width: 1200px) {
  .contentInner {
  }
  .title {
    font-size: 20px;
    line-height: 27px;
  }
  .smallField {
    > input {
      min-height: 45px;
    }
  }
  .fieldLabel {
    font-size: 18px;
    line-height: 21px;
  }
  .fieldDescription {
    font-size: 17px;
  }
  .cloverButton {
    height: 45px;
    top: 0px;
    left: 295px;
  }
  .cancelButton {
    width: 360px;
    height: 90px;
  }
  .saveButton {
    width: 360px;
    height: 90px;
  }
  .spaceBetweenRow {
    margin-bottom: 30px;
  }
}
