.wizardFormField {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.mainContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.wizardFormFieldInner {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tf {
  width: 440px;
}

.tdInvalid {
  .tfInput {
    color: rgba(223, 21, 28, 0.5);
  }

  .tfLine {
    background-color: #df151c;
  }

  .tfLabel {
    color: #df151c;
  }
}

.tfInput {
  display: block;
  width: 100%;
  padding: 12px 0;
  color: #595959;
  text-align: center;
  font-size: 30px;
  line-height: 35px;
  letter-spacing: 0.08em;
  border: 2px solid #d9d9d9;
  background-color: transparent;
}

.tfInput:focus {
  outline: none;
}

.tfLabel {
  margin: 15px 0 0;
  color: #595959;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.errorMessage {
  display: block;
  margin-top: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.04em;
  color: rgba(223, 21, 28, 0.7);
}

.required {
  margin-left: 5px;
  color: #df151c;
}
