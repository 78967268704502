.cartItem {
  display: flex;
  margin-bottom: 1em;
  &Schedule {
    font-size: 12px;
    @media screen and (max-width: 1000px) {
      font-size: 12px;
    }
  }
}
.cartItemCover {
  min-width: 50px;
  background: #000000;
  border-radius: 3px;
  img {
    display: block;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.cartItemInfo {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: 15px;
  padding-right: 15px;
  overflow: hidden;
}
.productTitle {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &Inner {
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.04em;
    color: #000000;
    white-space: normal;
  }
}
.centreTitle {
  display: flex;
  align-items: center;
  &Inner {
    font-weight: normal;
  }
}
.ownerInfo {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &Inner {
    font-style: normal;
    letter-spacing: 0.04em;
    color: #000000;
  }
}
.cartItemRightSide {
  &Inner {
    display: flex;
    height: 100%;
  }
}
.cartItemQuantity {
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.cartItemPrice {
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.cartItemDelete {
  display: block;
  background: #f2f2f2;
  height: 100%;
  border-radius: 3px 0 0 3px;
  border: none;
  touch-action: manipulation;
}
.productOptions {
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.04em;
  color: #000000;
  span {
    padding-right: 2px;
  }
}
@media (min-width: 768px) {
  .cartItemTitle {
    font-size: 18px;
    line-height: 20px;
  }
  .cartItemCover {
    width: 50px;
    height: 50px;
  }
  .cartItemDelete {
    width: 45px;
    // height: 50px;
  }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
  .cartItemTitle {
    font-size: 20px;
    line-height: 23px;
  }
  .cartItemCover {
    width: 90px;
    height: 90px;
  }
  .cartItemDelete {
    width: 60px;
    // height: 90px;
  }
}
