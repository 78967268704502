.timeSlotGrid {
  padding: 20px 30px 50px;
  position: relative;
  display: block;
  grid-gap: 30px 20px;
  overflow: hidden;
  &Container {
    display: flex;
    transition: 1s;
  }
  &Title {
    padding: 0 30px;
    font-size: 18px;
    font-weight: 700;
  }
  &Arrow {
    max-width: 50px;
    position: absolute;
    width: 100%;
    top:calc(50% - 82px);
    opacity: 0.9;
    &Left {
      transform: scaleX(-1);
      left: 10px;
      z-index: 2;
    }
    &Right {
      right: 10px;
      z-index: 2;
    }
    &Unactive{
      opacity: .5;
    }
  }
  &Wrapper {
    &:last-child > div > div{
      border-bottom: 0;
    }
  }
}
@media (min-width: 768px) {
  .timeSlotsList {
    margin: 0 -15px;
    > div {
      margin: 0 10px 15px;
    }
  }
}
