.addGuestForm {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  background-color: #ffffff;
  z-index: 20;
}
.flexcontainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  background: #f2f2f2;
}
.container {
  display: flex;
  min-width: 100%;
  min-height: 100%;
  flex-direction: column;
}
.content {
  display: flex;
  flex: auto;
}
.contentWaiver {
  display: flex;
  flex: 1;
  flex-direction: column;
  background: #f2f2f2;
}

.mb40 {
  margin-bottom: 40px;
}
.keyboardContainer {
  position: relative;
}

.minorCustomerAlert {
  padding: 65px 0 0 0;
  width: 600px;
}
.minorCustomerAlertTitle {
  margin: 0 0 25px;
  font-weight: 500;
  font-size: 32px;
  line-height: 37px;
  text-align: center;
  letter-spacing: 0.06em;
  color: #000000;
}
.minorCustomerAlertSubTitle {
  margin: 0 0 65px;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #000000;
}
.minorCustomerAlertFooter {
  display: flex;
}
.backButton {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 360px;
  height: 90px;
}
.continueButton {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 360px;
  height: 90px;
}
.warningAlert {
  padding: 65px 0 0 0;
  width: 650px;
  &Title {
    margin: 0 0 25px;
    font-weight: 500;
    font-size: 32px;
    line-height: 37px;
    text-align: center;
    letter-spacing: 0.06em;
    color: #df151c;
  }
  &SubTitle {
    margin: 0 0 65px;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 40px;
    text-align: center;
    letter-spacing: 0.04em;
    color: #df151c;
  }
  &Footer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &Button {
    width: 100%;
  }
}

.waiverAgreementTextContainer {
  width: calc(100% - 40px);
  margin: 0 20px 20px;
}
.waiverAgreementTextTitle {
  display: block;
  margin-bottom: 15px;
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
  letter-spacing: 0.06em;
  color: #000000;
}
.waiverAgreementTextText {
  overflow: hidden;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid rgb(217, 217, 217);
  box-shadow: 0px 15px 60px rgba(26, 20, 63, 0.1);
  > div {
    padding: 10px;
    color: #595959;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.04em;
  }
}
.waiverAgreement {
  margin: 0 20px 20px;
}
.waiverAgreementTextText2 {
  height: 75px;
  overflow: hidden;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid rgb(217, 217, 217);
  box-shadow: 0px 15px 60px rgba(26, 20, 63, 0.1);
  > div {
    padding: 10px;
    color: #595959;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.04em;
  }
}
.waiverAgreement {
  margin: 0 20px 20px;
}
.waiverAgreementSignatureContainer {
  margin: 0 20px;
}
.waiverAgreementSignatureTitle {
  display: block;
  margin-bottom: 15px;
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  letter-spacing: 0.06em;
  color: #000000;
}
.waiverAgreementSignatureCanvas {
  display: flex;
  justify-content: center;
  align-items: center;
}
.waiverAgreementSignatureCanvasInner {
  border: 1px solid #595959;
  box-shadow: 0px 0px 30px rgba(26, 20, 63, 0.1);
  border-radius: 5px;
}

.navigation {
  display: flex;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
}
.navigationFilter {
  flex: 1;
}
.iAgreeTextbox {
  overflow: hidden;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid rgb(217, 217, 217);
  box-shadow: 0px 15px 60px rgba(26, 20, 63, 0.1);
  > div {
    padding: 10px;
    color: #595959;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.04em;
  }
}
@media (min-width: 768px) {
  .navigationButton {
    width: 200px;
    height: 60px;
  }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
  .navigationButton {
    width: 360px;
    height: 90px;
  }
}
