.select {
  // width: 440px;
  // height: 60px;
}
.select {
  display: block;
  width: 100%;
  // margin-bottom: 25px;
  color: #595959;
  text-align: center;
  font-size: 30px;
  line-height: 35px;
  letter-spacing: 0.08em;
  border: none;
}
.selectLine {
  height: 1px;
  width: 100%;
  background-color: #d9d9d9;
}
.selectLabel {
  margin: 15px 0 0;
  color: #595959;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
