.productGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 25px;
  padding: 25px;
}
@media (min-width: 1500px) {
  .productGrid {
    grid-template-columns: repeat(5, 1fr);
  }
}
@media (min-width: 2000px) {
  .productGrid {
    grid-template-columns: repeat(6, 1fr);
  }
}
