.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
}
.contentInner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.errorIcon {
}
.separator {
  width: 80px;
  height: 1px;
  margin: 25px 0;
  background: #ffffff;
  opacity: 0.4;
}
.subtotal {
  margin: 0 0 30px;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.4);
  .value {
    color: #ffffff;
  }
}
.tax {
  margin: 0 0 50px;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.4);
  .value {
    color: #ffffff;
  }
}
.balance {
  margin: 0 0 25px;
  font-style: normal;
  font-weight: bold;
  font-size: 60px;
  line-height: 60px;
  text-align: center;
  letter-spacing: 0.14em;
  text-transform: uppercase;
  color: #ffffff;
  .value {
  }
}
.description {
  margin: 0 0 90px;
  font-style: normal;
  font-weight: 300;
  font-size: 30px;
  line-height: 60px;
  text-align: center;
  letter-spacing: 0.07em;
  color: #ffffff;
}
.icons {
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    display: block;
  }
}
