.root {
  color: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
  & img {
    width: 250px;
    margin: 50px 0;
  }
  & h1 {
    margin: 0;
    font-weight: 500;
    font-size: 38px;
  }
}
