.datepicker.default {
  position: relative;
  background: #f2f2f2;
}
/*.datepicker .datepicker-viewport {*/
/*    height: 352px;*/
/*}*/
.datepicker .datepicker-content {
  padding: 40px 0 !important;
}
.datepicker.default .datepicker-wheel {
  border-color: transparent;
  border-top: none !important;
  border-bottom: none !important;
  /*height: 80px;*/
  /*top: 50%;*/
  /*margin-top: -40px;*/
}
.datepicker .datepicker-scroll > li {
  /*height: 67px;*/
  /*box-sizing: border-box;*/
  color: #797979 !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 27px !important;
  line-height: 32px !important;
  cursor: pointer !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.datepicker.default .datepicker-scroll li:nth-child(4),
.datepicker.default .datepicker-scroll li:nth-child(3),
.datepicker.default .datepicker-scroll li:nth-child(8),
.datepicker.default .datepicker-scroll li:nth-child(9) {
  color: #b9b9b9 !important;
}
.datepicker.default .datepicker-scroll li:nth-child(6) {
  color: #df151c !important;
}
