.container {
  position: relative;
  display: flex;
  flex: 1;
}
.content {
  display: flex;
  flex: 1;
}
.contentInner {
  flex: 1;
}
.productsList {
  overflow: hidden;
  overflow-y: auto;
  position: relative;
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
