.assignActivities {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px;
    height: 100%;
    padding-top: 0;
    padding-bottom: 100px;
    overflow: auto;
}



.imgBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
    padding-bottom: 10px;
    margin: 30px 0 40px 0;
}

.activityImg {
    width: 300px;
    height: 200px;
}

.activityTitle {
    margin: 15px 0 8px 0px;
    font-weight: 500;
}

.activityStartTime {
    margin: 0;
    font-weight: 300;
}

.guests {
    flex-wrap: wrap;
    width: 100%;
    display: flex;
    justify-content: center;
    // justify-content: center;
    // grid-template-columns: repeat(auto-fill, 300px);
    grid-gap: 20px;
    & > div {
        max-width: 300px;
        & > div > div {
            margin-bottom: 25px;
        };
        & > div > div > div {
            width: 100px;
            height: 100px;
            margin-bottom: 5px;
        }
    };
}

.confirmRemoveFromGroup {
    padding: 0 30px;
    p {
      text-align: center;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 40px;
      letter-spacing: 0.04em;
      color: #595959;
    }
  }

.title {
    margin: 0;
    font-weight: 500;
    text-align: center;
}

.desc {
    margin: 0;
    margin-bottom: 25px;
    color: #595959;
    font-size: 18px;
    font-weight: 500;
    margin-top: 8px;
    text-align: center;
}
