.productOptionsContainer {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background-color: #ffffff;
}
.productOptionButtons {
  display: flex;
}
.productOptionsContainerSmall {
  width: 500px;
  height: 700px;
}
.productOptionsContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px 20px 20px;
}
.title {
  color: #959595;
  text-align: center;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  margin: 0 0 10px;
}
.productName {
  color: #000000;
  text-align: center;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  margin: 0 0 10px;
}
.optionsList {
  margin-bottom: 15px;
}
.optionsListSmall {

}

.optionsItem {
  display: flex;
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }

  &Btn {
    display: block;
    width: 40px;
    height: 40px;
    background-color: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 2px;
    margin-right: 30px;
    &Active {
      border: 1px solid #DF151C;
      background: #DF151C url('../../assets/icons/tick.svg') no-repeat center;
    }
  }
  &Title {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 0.04em;
    color: #000000;
  }
}
.customOptionWrapper {
  flex: 1;
  textarea {
    height: 100%;
    width: 100%;
    color: #000000;
    padding: 10px;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 0.04em;
    border: 1px solid #D9D9D9;
    border-radius: 2px;
    &:focus{
      outline: 0;
    }
  }
}
.navigation {
  position: relative;
  display: flex;
  justify-content: center;
  &Button {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 240px;
    height: 60px;
    &Cancel {
      position: absolute;
      bottom: 0;
      width: 240px;
      height: 60px;
      left: 0;
    }
  }
}
.navigationButtonCancel {
  left: 0;
  right: auto;
}
