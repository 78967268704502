.button {
  display: block;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 23px;
  color: #000000;
  background: #ffffff;
  padding: 0;
  border: 1px solid #dedede;
  cursor: pointer;
  touch-action: manipulation;
  &:active {
    background: #f2f2f2;
    border-color: #f2f2f2;
  }
  &:focus {
    outline: none;
  }
  img {
    display: inline-block;
  }
}

@media (min-width: 768px) {
  .button {
    width: 90px;
    height: 90px;
    border-radius: 45px;
  }
}

@media (min-width: 1200px) {
  .button {
    width: 120px;
    height: 120px;
    border-radius: 60px;
  }
}
