.inputNumber {
  button {
    width: 50px;
    height: 50px;
    padding: 0;
    border-radius: 2px;
    border: none;
  }
  input {
    border: none;
    height: 50px;
    width: 65px;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 50px;
    text-align: center;
    letter-spacing: 0.06em;
    color: #000000;
    text-align: center;
    background-color: transparent;
  }
}
.minus {
  color: #000000;
  background-color: #ffffff;
  cursor: pointer;
}
.plus {
  color: #ffffff;
  background-color: #df151c;
  cursor: pointer;
}
