.screenWrapper {
  background-color: #df151c;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contentWrapper {
  width: 50%;
  margin: auto;
}

.imageWrapper {
  display: flex;
  justify-content: center;
  margin: 3em 0;
}

.image {
  width: 20rem;
}

.contentWrapper h1 {
  color: white;
  text-align: center;
  font-size: 3rem;
  margin: 0;
}

.contentWrapper h2 {
  color: white;
  text-align: center;
  font-size: 3rem;
  margin-bottom: 2em;
  font-weight: 400;
  margin-top: 0;
}

.continueButton {
  margin: auto;
  margin-top: 7em;
  width: 70%;
  height: 5rem;
  border-radius: 5px;
  font-size: 2rem;
}
