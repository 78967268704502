.comboCartItemsWrapper {
  display: flex;
  // justify-content: space-between;
}
.comboCartItemsInfoWrapper {
  width: 87%;
}

.comboCartItemsHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5em;
}

.comboName {
  font-size: 1.1rem;
  margin-bottom: 0rem;
  margin-right: 0.5em;
}

.cartItemsDelete {
  display: block;
  background: #f2f2f2;
  height: 100%;
  border-radius: 3px 0 0 3px;
  border: none;
  touch-action: manipulation;
  &Disabled {
    opacity: 0.3;
  }
  &Active {
    opacity: 1;
  }
}

.guestLimitationsWarningModalWrapper {
  padding: 0 30px;
  p {
    font-style: normal;
    font-weight: 900;
    text-align: center;
    font-size: 20px;
    line-height: 40px;
    letter-spacing: 0.04em;
    margin: 0;
  }
  div {
    margin: 1em 0;
  }
}

@media (min-width: 768px) {
  .comboCartItemsInfoWrapper {
    width: 91%;
  }
  .cartItemsDelete {
    width: 45px;
  }
}

@media (min-width: 1200px) {
  .cartItemsDelete {
    width: 60px;
  }
}
