.timeSlotsGridRow {
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 45px 35px;
  display: grid;
  padding-bottom: 50px;
  flex: 0 0 100%;
  margin-right: 30px;
}

@media (min-width: 768px) {
  .timeSlotsGridRow {
    grid-gap: 20px;
  }
}

@media (min-width: 875px) {
  .timeSlotsGridRow {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (min-width: 1024px) {
  .timeSlotsGridRow {
    grid-gap: 35px 25px;
  }
}
@media (min-width: 1500px) {
  .timeSlotsGridRow {
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 40px 30px;
  }
}

@media (min-width: 1700px) {
  .timeSlotsGridRow {
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 45px 35px;
  }
}

@media (min-width: 1900px) {
  .timeSlotsGridRow {
    grid-template-columns: repeat(7, 1fr);
  }
}
