.wizardFormField {
  display: flex;
  height: 100%;
  flex-direction: column;
}
.mainContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.titleWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.title {
  margin: 0;
  font-weight: bold;
  font-size: 48px;
  line-height: 56px;
  text-align: center;
  letter-spacing: 0.06em;
  color: #000000;
}
.required {
  margin-left: 5px;
  color: #df151c;
}
.separator {
  display: block;
  margin: 50px 0;
  width: 80px;
  height: 1px;
  background-color: #d9d9d9;
}
.errorMessage {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.04em;
  color: rgba(223, 21, 28, 0.7);
}
