.dp {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 100px;
  background-color: #f2f2f2;
}

@media (min-width: 768px) {
  .dp {
    padding-bottom: 60px;
  }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
  .dp {
    padding-bottom: 100px;
  }
}
