.wizardFormField {
  display: flex;
  height: 100%;
  flex-direction: column;
}
.mainContent {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.select {
  background: #f2f2f2;
}
.selectInner {
  width: 100%;
  display: flex;
  padding: 40px 0;
  justify-content: center;
}
@media (min-width: 768px) {
  .select {
    padding-bottom: 60px;
  }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
  .select {
    padding-bottom: 100px;
  }
}
