.cloverButton {}


.cancelButton {
  position: absolute;
  bottom: 0;
  left: 0;
}
.saveButton {
  position: absolute;
  bottom: 0;
  right: 0;
}
.cloverPassModal {
  width: 400px;
  background-color: #ffffff;
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 40px;
    letter-spacing: 0.04em;
    text-align: center;
  }
}
.cloverPass {
  font-weight: bold;
  text-align: center;
  font-size: 40px;
  line-height: 45px;
  letter-spacing: 0.04em;
}
.cloverPassButtonWrap {
  display: flex;
  align-items: center;
  justify-content: center;
}
.cloverPassButton {
  width: 100%;
}

.cloverButton {

}


@media (min-width: 768px) {
  .cloverButton {
    height: 45px;
  }
}
@media (min-width: 1024px) {
  .cloverButton {
    height: 35px;
  }
}
@media (min-width: 1200px) {
  .cloverButton {
    height: 45px;
  }
}
