.countdownTimerPhoto {
  //display: flex;
  text-align: center;
  margin-top: 100px;
  font-size: 60px;
}
.countdownTimerPhotoInner {
  font-style: normal;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0.06em;
  color: #000000;
}
