.memberCard {
  position: relative;
  flex: 1 1 calc(100% - 25px);
  max-width: calc(100% - 25px);
  @media screen and (min-width: 768px) {
    flex: 1 1 calc(50% - 25px);
    max-width: calc(50% - 25px);
  }
  @media screen and (min-width: 1200px) {
    flex: 1 1 calc(33% - 25px);
    max-width: calc(33% - 25px);
  }
  @media screen and (min-width: 1600px) {
    flex: 1 1 calc(25% - 25px);
    max-width: calc(25% - 25px);
  }
}
.memberCardActive {
  > div {
    border: 4px solid #df151c;
  }
}
.memberCardInner {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-top: 60px;
  padding-bottom: 30px;
  cursor: pointer;
  border: 4px solid transparent;
  background-color: #fff;
  align-items: center;
  justify-content: center;
}
.actionButton {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  padding: 0;
  border: none;
  border-radius: 50%;
  background-color: #df151c;
}
.memberCardName {
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  line-height: 28px;
  overflow: hidden !important;
  width: 100%;
  margin: 0 0 20px;
  padding: 0 15px;
  text-align: center;
  white-space: nowrap;
  letter-spacing: 0.08em;
  text-overflow: ellipsis;
  color: #000;
}
.memberCardBirthday {
  font-weight: normal;
  font-style: normal;
  margin: 0;
  text-align: center;
  letter-spacing: 0.06em;
  color: #959595;
}
.memberCardGender {
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  line-height: 21px;
  position: absolute;
  top: 16px;
  right: 20px;
  text-align: right;
  letter-spacing: 0.14em;
  text-transform: uppercase;
  color: #959595;
}
.removeButton {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  button {
    display: flex;
    width: 60px;
    height: 60px;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    background-color: #ececec;
    border: none;
    touch-action: manipulation;
    &:focus {
      outline: none;
    }
    &:hover {
      cursor: pointer;
    }
  }
}
@media (min-width: 768px) {
  .memberCardAvatar {
    margin-bottom: 20px;
  }
  .memberCardBirthday {
    font-size: 24px;
    line-height: 28px;
  }
  .memberCardGender {
  }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
  //.memberCard {
  //  width: 400px;
  //  padding: 20px;
  //}
  .memberCardAvatar {
    margin-bottom: 40px;
  }
  .memberCardBirthday {
    font-size: 24px;
    line-height: 28px;
  }
}

.error {
  position: absolute;
  top: 0;
  background-color: rgba(172, 172, 172, 0.8);
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  font-weight: 500;
  font-size: 16px;
}
