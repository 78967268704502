.Warning {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 25px;
  background: white;
  &__icon {
    max-width: 300px;
    width: 100%;
    margin-bottom: 25px;
    @media screen and (max-width: 1600px) {
      max-width: 250px;
    }
    @media screen and (max-width: 1400px) {
      max-width: 200px;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 42px;
    text-align: center;
    div {
      margin: 25px 0;
    }
    @media screen and (max-width: 1600px) {
      font-size: 32px;
      div {
        margin: 20px 0;
      }
    }
    @media screen and (max-width: 1200px) {
      font-size: 25px;
      div {
        margin: 10px 0;
      }
    }
  }
  &__button {
    position: absolute;
    bottom: 0;
    height: 75px;
    display: flex;
    left: 0;
    justify-content: center;
    align-items: center;
    background: #df151c;
    color: #fff;
    font-size: 25px;
    cursor: pointer;
    width: 100%;
    &:hover {
      background: #fd3940;
    }
  }
}
