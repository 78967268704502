.container {
  display: flex;
  flex-direction: row;
  min-width: 100%;
  min-height: 100%;
}
.leftSide {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background: url('../../assets/images/bg.jpg');
  background-size: cover;
}
.leftSideContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.rightSide {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
}
.separator {
  width: 80px;
  height: 1px;
  background-color: #ffffff;
  opacity: 0.4;
}
.title {
  margin: 0;
  font-style: normal;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0.08em;
  color: #ffffff;
}
.phoneInformation {
  display: flex;
  align-items: center;
  width: 360px;
  margin-bottom: 30px;
}
.phoneInformationBack {
  display: block;
  width: 60px;
  height: 60px;
  margin-right: 20px;
  opacity: 0.4;
  border: 1px solid #ffffff;
  border-radius: 2px;
  background: none;
}
.phoneInformationNumber {
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 29px;
  letter-spacing: 0.2em;
  color: #ffffff;
}
.mainMessage {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.04em;
  color: rgba(255, 255, 255, 0.8);
}
@media (min-width: 768px) {
  .container {
    flex-direction: column;
  }
  .separator {
    margin: 15px 0;
  }
  .title {
    font-size: 24px;
    line-height: 30px;
  }
  .buttonStyle {
    width: 360px;
    height: 45px;
  }
  .rowMargin {
    margin-bottom: 20px;
  }
}
@media (min-width: 1024px) {
  .container {
    flex-direction: row;
  }
  .separator {
    margin: 25px 0;
  }
  .title {
    font-size: 40px;
    line-height: 47px;
  }
  .rowMargin {
    margin-bottom: 30px;
  }
}
@media (min-width: 1200px) {
  .buttonStyle {
    width: 360px;
    height: 60px;
  }
}
