.dropdown {
  background: #FFFFFF;
  box-shadow: 0 15px 60px rgba(26, 20, 63, 0.1);
  position: relative;
  cursor: pointer;
  z-index: 2;

  &Placeholder {
    cursor: pointer;
    min-height: 60px;
    display: block;
    width: 100%;
    padding: 12px 0;
    color: #595959;
    text-align: center;
    font-size: 30px;
    line-height: 35px;
    letter-spacing: 0.08em;
    border: 2px solid #d9d9d9;
    background-color: transparent;
    position: relative;
    &:before {
      content: "";
      background-image: url('data:image/svg+xml;utf8,<svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0.198688 0.308434C0.501742 -0.0552309 1.04222 -0.104366 1.40589 0.198688L6 4.02711L10.5941 0.198688C10.9578 -0.104366 11.4983 -0.0552309 11.8013 0.308434C12.1044 0.672098 12.0552 1.21258 11.6916 1.51563L6.54873 5.80133C6.23086 6.06622 5.76914 6.06622 5.45127 5.80133L0.308434 1.51563C-0.0552309 1.21258 -0.104366 0.672098 0.198688 0.308434Z" fill="gray"/></svg>');
      position: absolute;
      right: 21px;
      top: calc(50% - 6px);
      width: 24px;
      height: 12px;
      background-size: cover;
      z-index: 1;
    }

    &IsOpen {
      &:before {
        transform: rotate(-180deg);
      }
    }
  }

  &List {
    max-height: 304px;
    overflow-y: auto;
    position: absolute;
    width: 100%;
    background: #FFFFFF;
    border-bottom: 2px solid #d9d9d9;
    display: none;

    &IsOpen {
      display: block;
    }

    &Item {
      cursor: pointer;
      display: block;
      width: 100%;
      padding: 12px 0;
      color: #595959;
      text-align: center;
      font-size: 30px;
      line-height: 35px;
      letter-spacing: 0.08em;
      border-bottom: 2px solid #d9d9d9;
      border-left: 2px solid #d9d9d9;
      border-right: 2px solid #d9d9d9;
      background-color: transparent;

      &:last-child {
        border-bottom: 0;
      }

      &:hover, &Selected {
        background: #d9d9d9;
      }
    }
  }
}
