.container {
  display: flex;
  flex: 1;
}
.content {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.panel {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-bottom: 1px solid #d9d9d9;
}
.title {
  margin: 0;
  font-style: normal;
  font-weight: bold;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #000000;
}
.price {
  display: flex;
  position: absolute;
  top: 11px;
  right: 11px;
  align-items: center;
  justify-content: center;
  background: url('../../assets/icons/default-ticket.svg');
  background-size: cover;
}
.priceInner {
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.07em;
  color: #000000;
}
.productInformation {
  display: flex;
  position: relative;
}
.productCover {
  img {
    display: block;
    object-fit: cover;
  }
}
.productInformation {
  display: flex;
  background-color: #ffffff;
}
.productDescription {
  display: flex;
  flex: 1;
  align-items: center;
  > div {
    display: block;
    display: -webkit-box;
    margin: 0 30px;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 18px;
    line-height: 36px;
    letter-spacing: 0.04em;
    color: #4b4b4b;
  }
}
.productDetails {
  overflow: hidden;
  overflow-y: auto;
  // padding: 0 35px;
  // height: 300px;
  // flex: 1;
  position: relative;
  // background-color: red;
}
.productDetailsInner {
}
.productsList {
  margin: 50px;
}
.scrollButton {
  position: absolute;
  left: 0;
  right: 0;
  height: 109px;
  width: 100%;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 36px;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #959595;
  z-index: 10;
  border: none;
  span {
    margin-right: 10px;
  }
}
.scrollButtonUp {
  top: 0;
  background: linear-gradient(0deg, rgba(242, 242, 242, 0) 0%, #f2f2f2 100%);
}
.scrollButtonDown {
  bottom: 0;
  background: linear-gradient(180deg, rgba(242, 242, 242, 0) 0%, #f2f2f2 100%);
}
.addToCartInner {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}
.incompleteWaiver {
  padding: 0 30px;
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 40px;
    letter-spacing: 0.04em;
    color: #595959;
  }
  ul {
    li {
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 40px;
      letter-spacing: 0.04em;
      color: #595959;
    }
  }
}
.packSlotsCounter {
  display: flex;
  position: absolute;
  width: 220px;
  height: 50px;
  color: #ffffff;
  background-color: #df151c;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  bottom: -20px;
  left: 50%;
  margin-left: -110px;
  z-index: 20;
}
.footerPanel {
  position: relative;
}
.cancelButton {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 200px;
  height: 60px;
}
.nextButton {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 200px;
  height: 60px;
}

@media (min-width: 768px) {
  .panel {
    height: 60px;
  }
  .title {
    font-size: 20px;
  }
  .price {
    width: 113px;
    height: 41px;
  }
  .priceInner {
    font-size: 16px;
    line-height: 20px;
  }
  .productInformation {
    min-height: 200px;
  }
  .productCover {
    width: 200px;
    height: 200px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .productDescription {
    > div {
      -webkit-line-clamp: 5;
    }
  }
  .productDetailsInner {
    padding-top: 20px;
    padding-bottom: 60px;
  }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
  .panel {
    height: 110px;
  }
  .title {
    font-size: 26px;
  }
  .price {
    width: 240px;
    height: 88px;
  }
  .priceInner {
    font-size: 26px;
    line-height: 30px;
  }
  .productCover {
    width: 300px;
    img {
      display: block;
      width: 300px;
    }
  }
}

.prompts {
  padding: 0 30px;
  flex-direction: column;
  align-items: center;
  display: flex;
  &Label {
    margin-bottom: 15px;
  }
  &Subheader{
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 40px;
    letter-spacing: 0.04em;
    color: #595959;
    margin-bottom: 25px;
    margin-top: 0;
    text-align: center;
  }
  &Labels {
    margin-bottom: 25px;
    //&::-webkit-scrollbar {
    //  background: none;
    //  width: 0;
    //  display: none;
    //}
    overflow-y: auto;
    max-height: calc(100vh - 332px);
  }
}

.scrollDown {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 48.96%);
  position: absolute;
  bottom: 80px;
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 15px;
}


//schedule
.timeSlotGrid {
  // display: flex;
  // flex-wrap: wrap;
  // justify-content: center;
  padding: 50px 30px 100px;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px 20px;
}
@media (min-width: 768px) {
  .timeSlotsList {
    margin: 0 -15px;
    > div {
      margin: 0 10px 15px;
    }
  }
}

@media (min-width: 875px) {
  .timeSlotGrid {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (min-width: 1024px) {
  .timeSlotGrid {
    grid-gap: 35px 25px;
  }
}
//@media (min-width: 1200px) {
//  .timeSlotGrid {
//    grid-template-columns: repeat(6, 1fr);
//  }
//}
@media (min-width: 1500px) {
  .timeSlotGrid {
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 40px 30px;
  }
}

@media (min-width: 1700px) {
  .timeSlotGrid {
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 45px 35px;
  }
}

@media (min-width: 1900px) {
  .timeSlotGrid {
    grid-template-columns: repeat(7, 1fr);
  }
}
