.countdownTimer {
  display: flex;
}
.countdownTimerInner {
  font-style: normal;
  font-weight: bold;
  font-size: 80px;
  line-height: 80px;
  text-align: center;
  letter-spacing: 0.06em;
  color: #000000;
}
