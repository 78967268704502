.select-search {
  width: 440px;
  position: relative;
  // font-family: 'Nunito Sans', sans-serif;
  box-sizing: border-box;
}

.select-search *,
.select-search *::after,
.select-search *::before {
  box-sizing: inherit;
}

/**
 * Value wrapper
 */
.select-search__value {
  position: relative;
  z-index: 1;
}

//.select-search__value::after {
//  content: '';
//  display: inline-block;
//  position: absolute;
//  top: calc(50% - 9px);
//  right: 19px;
//  width: 11px;
//  height: 11px;
//}

/**
 * Options wrapper
 */
.select-search__select {
  margin-top: 20px;
  background: #fff;
  // box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
}

.select-search__input {
  display: block;
  width: 100%;
  padding-bottom: 25px;
  color: #595959;
  text-align: center;
  font-size: 30px;
  line-height: 35px;
  letter-spacing: 0.08em;
  border: none;
  border-bottom: 1px solid #d9d9d9;
  background: transparent;
}

/**
 * Options
 */
.select-search__options {
  margin: 0;
  padding: 0;
  list-style: none;
}

/**
 * Option row
 */
.select-search__row:not(:first-child) {
  // border-top: 1px solid #eee;
}

/**
 * Option
 */
.select-search__option {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 16px;
  background: #fff;
  border: none;
  outline: none;
  // font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  text-align: left;
  cursor: pointer;
}

.select-search--multiple .select-search__option {
  height: 48px;
}

.select-search__option.is-selected {
  // background: #2FCC8B;
  color: #fff;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
  background: rgba(47, 204, 139, 0.1);
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
  // background: #2eb378;
  color: #fff;
}

/**
 * Group
 */
.select-search__group-header {
  font-size: 10px;
  text-transform: uppercase;
  background: #eee;
  padding: 8px 16px;
}

/**
 * States
 */
.select-search.is-disabled {
  opacity: 0.5;
}

.select-search:not(.is-disabled) .select-search__input {
  cursor: pointer;
}

/**
 * Modifiers
 */
.select-search--multiple {
  border-radius: 3px;
  overflow: hidden;
}

.select-search:not(.is-loading):not(.select-search--multiple) .select-search__value::after {
  transform: rotate(45deg);
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  pointer-events: none;
}

.select-search--multiple .select-search__input {
  cursor: initial;
}

.select-search--multiple .select-search__input {
  border-radius: 3px 3px 0 0;
}

.select-search--multiple:not(.select-search--search) .select-search__input {
  cursor: default;
}

.select-search:not(.select-search--multiple) .select-search__input:hover {
  // border-color: #2FCC8B;
}

.select-search:not(.select-search--multiple) .select-search__select {
  position: absolute;
  z-index: 2;
  top: 44px;
  right: 0;
  left: 0;
  border-radius: 3px;
  overflow: auto;
  max-height: 360px;
}

.select-search--multiple .select-search__select {
  position: relative;
  overflow: auto;
  max-height: 300px;
  border-top: 1px solid #eee;
  border-radius: 0 0 3px 3px;
}
