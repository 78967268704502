.avatar {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  overflow: hidden;
}
.avatarImg {
  display: block;
  margin: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
