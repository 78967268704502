.container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
}
//.row {
//  display: flex;
//  flex-direction: row;
//  justify-content: space-between;
//  margin-bottom: 30px;
//}
//.rowLast {
//  margin-bottom: 0px;
//}
@media (min-width: 768px) {
  .container {
    width: 350px;
  }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
  .container {
    width: 420px;
  }
}
