.titleWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  background-color: #fff;
  border-bottom: 1px solid #d9d9d9;
}
.title {
  margin: 0;
  font-size: 20px;
  line-height: 30px;
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
  letter-spacing: .1em;
}
