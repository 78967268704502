.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
}
.contentInner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.errorIcon {
}
.separator {
  width: 80px;
  height: 1px;
  margin: 25px 0;
  background-color: #ffffff;
  opacity: 0.4;
}
.errorMessageContainer {
  max-width: 920px;
}
.errorMessage {
  margin: 0;
  font-style: normal;
  font-weight: 300;
  font-size: 30px;
  line-height: 60px;
  text-align: center;
  letter-spacing: 0.07em;
  color: #ffffff;
}
