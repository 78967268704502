.wizardFormField {
  display: flex;
  height: 100%;
  flex-direction: column;
}
.mainContent {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
