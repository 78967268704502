.productOptionsSlider {

}

.header {
  display: flex;
  justify-content: space-between;
  background: #F2F2F2;
  margin-bottom: 20px;
}
.navigationBtn {
  width: 40px;
  height: 40px;
  border: none;
  background-color: rgba(0, 0, 0, 0.05);
}
.navigationBtnNext {
  background-image: url('../../assets/icons/slider-arrow-next.svg');
  background-repeat: no-repeat;
  background-position: center;
}
.navigationBtnPrev {
  background-image: url('../../assets/icons/slider-arrow-prev.svg');
  background-repeat: no-repeat;
  background-position: center;
}
.sliderStatus {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.06em;

  color: #000000;
}
.slider {
  height: 150px;
  overflow-y: scroll;
  overflow-x: hidden;
  max-width: calc(100vw - 90px);
}
.sliderSmall {
  height: 300px;
}
