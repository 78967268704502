.pagination {
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 35px;
  letter-spacing: 0.06em;
  color: #959595;
}
.paginationStep {
  color: #000000;
}
@media (min-width: 768px) {
  .pagination {
    font-size: 14px;
    line-height: 16px;
  }
}
@media (min-width: 1024px) {
  .pagination {
    font-size: 18px;
    line-height: 24px;
  }
}
@media (min-width: 1200px) {
}


.mainMenu {
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #FFFFFF;
  padding: 0 20px;
  &dark {
    background-color: transparent;
  }
  &Border {
    border-bottom: 1px solid rgb(217, 217, 217);
  }
  &Shadow {
    box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.15);
  }
}
.leftContainer {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  gap: 0 20px;
}
.centerContainer {
  display: flex;
  padding: 0 20px;
  justify-content: center;
}
.rightContainer {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  gap: 0 20px;
}
.confirmLogOutModal {
  text-align: center;
}
@media (min-width: 768px) {
  .headerWhiteContainer {
    height: 60px;
    margin-bottom: 15px;
    padding: 0 15px;
  }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
  .headerWhiteContainer {
    height: 90px;
    margin-bottom: 30px;
    padding: 0 15px;
  }
}
