.eventButtonStyle {
  height: 6rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  padding: 1rem;
  font-size: 0.9rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-align: center;
}

.selectedEvent {
  border: 4px solid #df151c;
}
