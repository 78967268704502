.wrapperDiv {
  position: relative;
  top: 0;
  right: 0;
  width: 30%;
  max-width: 100%;
  height: 10rem;
  z-index: 3;
  width: 240px;
  height: 88px;
}

.text {
  position: absolute;
  top: 50%;
  right: 20%;
  transform: translate(0, -55%);
  color: black;
  font-size: 28px;
  font-weight: 700;
  padding: 4px;
  z-index: 99;
}

@media (min-width: 768px) {
  .wrapperDiv {
    width: 113px;
    height: 41px;
  }
  .text {
    font-size: 18px;
  }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
  .wrapperDiv {
    width: 240px;
    height: 88px;
  }
  .text {
    font-size: 28px;
    right: 25%;
  }
}
