.container {
    display: flex;
    width: fit-content;
    max-width: 450px;
    flex-direction: column;
    background: #ffffff;
  
}
.footer {
  display: flex;
  & > button:first-child {
    background: #F2F2F2;
  }
}
.title {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 37px;
  text-align: center;
  letter-spacing: 0.06em;
  color: #000000;
}
.description {
  padding: 0 20px;
  margin-bottom: 20px;
  * {
    margin: 0;
    color: #6E6E6E;
    font-size: 16px; 
    font-weight: 500;
  };
  & > ul {
    margin: 5px 0 10px 0;
  }
}
.button {
  width: 100%;
  height: 50px;
  font-size: 16px;
  line-height: 19px;
}