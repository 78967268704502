.container {
  position: relative;
  display: flex;
  flex: 1;
}
.content {
  display: flex;
  flex: 1;
}
.contentInner {
  flex: 1;
}
.titleWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border-bottom: 1px solid #d9d9d9;
}
.title {
  margin: 0;
  font-style: normal;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #000000;
}
.productsList {
  overflow: hidden;
  overflow-y: auto;
  // padding: 0 35px;
  // height: 300px;
  // flex: 1;
  position: relative;
}
@media (min-width: 768px) {
  .titleWrap {
    height: 60px;
  }
  .title {
    font-size: 20px;
    line-height: 30px;
  }
  .productsListInner {
    // padding: 15px;
    // margin: 20px 10px 60px;
  }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
  .titleWrap {
    height: 110px;
  }
  .title {
    font-size: 26px;
    line-height: 30px;
  }
  //.productsListInner {
  //  margin: 30px 15px 80px;
  //}
}
