.signatureCanvas {
  width: 650px;
  height: 300px;
  position: relative;
}
.signatureCanvasInfo {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.signatureCanvasInfo h3 {
  margin: 0 0 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 56px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #c7c7c7;
}
.signatureCanvasInfo h4 {
  margin: 0;
  font-style: italic;
  font-weight: normal;
  font-size: 20px;
  line-height: 42px;
  text-align: center;
  letter-spacing: 0.06em;
  color: #6d6d6d;
}
.signatureCanvasWrapper {
  position: relative;
  z-index: 2;
}
