.tf {
  width: 440px;
}
.tdInvalid {
  .tfInput {
    color: rgba(223, 21, 28, 0.5);
  }
  .tfLine {
    background-color: #df151c;
  }
  .tfLabel {
    color: #df151c;
  }
}
.tfInput {
  display: block;
  width: 100%;
  margin-bottom: 25px;
  color: #595959;
  text-align: center;
  font-size: 30px;
  line-height: 35px;
  letter-spacing: 0.08em;
  border: none;
  background-color: transparent;
}
.tfInput:focus {
  outline: none;
}
.tfLine {
  height: 1px;
  width: 100%;
  background-color: #d9d9d9;
}
.tfLabel {
  margin: 15px 0 0;
  color: #595959;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
.errorMessage {
  display: block;
  margin-top: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.04em;
  color: rgba(223, 21, 28, 0.7);
}
.required {
  margin-left: 5px;
  color: #df151c;
}
