.navigationPanel {
  border-top: 60px solid transparent;
}

.productMembershipRequired {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: #f2f2f2;
  z-index: 101;
  overflow-y: auto;
  &__header {
    &Alert {
      background: rgba(223, 21, 28, 0.1);
      height: 4.625vw;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: Roboto, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 1.354vw;
      line-height: 30px;
      text-align: center;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: #df151c;
    }
    &Sub {
      gap: 0.521vw;
      border-top: 1px solid rgba(223, 21, 28, 0.3);
      border-bottom: 1px solid rgba(223, 21, 28, 0.3);
      min-height: 3.438vw;
      font-family: Roboto, sans-serif;
      font-style: normal;
      font-weight: normal;
      flex-wrap: wrap;
      font-size: 1.042vw;
      line-height: 30px;
      text-align: center;
      letter-spacing: 0.04em;
      color: #000000;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #ffffff;
      padding: 6px 5px;
    }
  }
  &__main {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 60px;
    margin-top: 1.024vw;
    &Title {
      max-width: 33.177vw;
      font-family: Roboto, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 1.667vw;
      line-height: 2.188vw;
      text-align: center;
      letter-spacing: 0.04em;
      color: #000000;
      margin-bottom: 1.302vw;
    }
  }
  &__list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 25px;
    padding: 0 25px;
    & > div {
      width: 17vw;
      & > div:first-child {
        height: 11vw;
      }
    }
    @media screen and (min-width: 1600px) {
      max-width: 80vw;
    }
  }
  &__navigation {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.guests {
  &__list {
    height: 2.917vw;
    display: flex;
    align-items: center;
    gap: 0.521vw;
    background: rgba(223, 21, 28, 0.1);
    border: 0.052vw solid rgba(223, 21, 28, 0.3);
    box-sizing: border-box;
    border-radius: 1.458vw;
    padding: 0.156vw 0.621vw 0.156vw 0.156vw;
    &Photo {
      display: flex;
      align-items: center;
      height: 100%;
      img {
        border-radius: 18.75vw;
        object-fit: cover;
        height: 100%;
      }
    }
    & > div:first-child {
      width: initial;
      margin-top: 1px;
      height: 100%;
      aspect-ratio: 1 / 1;
    }
    &Name {
      font-family: Roboto, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 1.042vw;
      line-height: 1.563vw;
      letter-spacing: 0.04em;
      color: #df151c;
    }
  }
}
