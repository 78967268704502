.TextInputWrapper {
  width: 360px;
  display: flex;
  flex-direction: column;
  position: relative;
}
.TextInputErrorMessageWrapper {
  position: absolute;
  top: -28px;
  right: 0;
  display: flex;
  justify-content: flex-end;
}
.TextInputErrorMessage {
  display: flex;
  padding: 6px 10px 7px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.04em;
  color: rgba(223, 21, 28, 0.8);
  background-color: #ffffff;
  border-radius: 3px 3px 0px 0px;
}
.TextInput {
  display: block;
  width: 100%;
  font-weight: 500;
  line-height: 29px;
  text-align: center;
  letter-spacing: 0.2em;
  border: none;
  border-radius: 2px;
}
.TextInput::placeholder {
  color: #ffffff;
}
.TextInput:focus {
  outline: none;
}
.TextInputActive {
  border: 1px solid #ffffff;
}
.themeDefault {
  background: #ffffff;
  border: 1px solid #d9d9d9;
}
.themeTransparent {
  color: #ffffff;
  background: rgba(255, 255, 255, 0.1);
}
@media (min-width: 768px) {
  .TextInput {
    min-height: 45px;
    font-size: 18px;
  }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
  .TextInput {
    min-height: 60px;
    font-size: 25px;
  }
}
