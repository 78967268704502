.contentInner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
  margin-bottom: 5em;
}
.formContainer {
  display: flex;
  justify-content: space-between;
  width: 800px;
  height: 530px;
  > div {
    width: 380px;
  }
}
.title {
  margin-top: 0;
  margin-bottom: 5px;
  font-style: normal;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0.08em;
  color: #ffffff;
}
.fieldLabel {
  margin: 0 0 5px;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.04em;
  color: rgba(255, 255, 255, 0.8);
}
.fieldLabelValue {
  margin: 0 0 5px;
  font-size: 18px;
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0.08em;
  text-align: center;
  color: #ffffff;
}
.fieldWithDescription {
  position: relative;
}
.fieldDescription {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-align: right;
  letter-spacing: 0.06em;
  color: rgba(255, 255, 255, 0.6);
}
.fieldDescriptionLeft {
  position: absolute;
  justify-content: flex-end;
  top: 0;
  left: -215px;
  width: 200px;
  bottom: 0;
}
.fieldDescriptionRight {
  position: absolute;
  top: 0;
  right: -215px;
  width: 200px;
  bottom: 0;
}
.error {
  justify-content: center;
  align-items: center;
  height: 60px;
  color: white;
  display: flex;
}
.navigation {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
}

.clover {
  position: relative;
  display: flex;
}
.cloverInput {
  flex: 1;
  margin-right: 20px;
}

.keyboardCheckbox {
  position: absolute;
  top: 100px;
  left: 20px;
  div {
    color: #ffffff;
  }
}
.smallField {
  width: 100%;
}
.cancelButton {
  position: fixed;
  bottom: 0;
  left: 0;
}
.saveButton {
  position: fixed;
  bottom: 0;
  right: 0;
}
@media (min-width: 768px) {
  .contentInner {
  }
  .title {
    font-size: 20px;
    line-height: 27px;
  }
  .fieldLabel {
    font-size: 14px;
    line-height: 16px;
  }
  .fieldWithDescription {
    width: 110px;
  }
  .fieldDescription {
    font-size: 14px;
  }
  .cloverButton {
    height: 45px;
    top: 0px;
    left: 375px;
  }
  .cancelButton {
    width: 240px;
    height: 60px;
  }
  .saveButton {
    width: 240px;
    height: 60px;
  }
  .spaceBetweenRow {
    margin-bottom: 20px;
  }
}
@media (min-width: 1024px) {
  .contentInner {
  }
  .title {
    font-size: 20px;
    line-height: 27px;
  }
  .smallField {
    > input {
      min-height: 35px;
    }
  }
  .cloverButton {
    height: 35px;
  }
  .spaceBetweenRow {
    margin-bottom: 10px;
  }
}
@media (min-width: 1200px) {
  .contentInner {
  }
  .title {
    font-size: 20px;
    line-height: 27px;
  }
  .smallField {
    > input {
      min-height: 45px;
    }
  }
  .fieldLabel {
    font-size: 18px;
    line-height: 21px;
  }
  .fieldDescription {
    font-size: 17px;
  }
  .cloverButton {
    height: 45px;
    top: 0px;
    left: 295px;
  }
  .cancelButton {
    width: 360px;
    height: 90px;
  }
  .saveButton {
    width: 360px;
    height: 90px;
  }
  .spaceBetweenRow {
    margin-bottom: 30px;
  }
}
