.container {
  display: flex;
  min-height: 100%;
  flex-direction: column;
}
.content {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}
.stepTitle {
  margin: 0 0 100px;
  font-weight: bold;
  font-size: 48px;
  line-height: 56px;
  text-align: center;
  letter-spacing: 0.06em;
  color: #000000;
}
.mb40 {
  margin-bottom: 40px;
}
.keyboardContainer {
  position: relative;
}
.keyboardContainerFooter {
}
.backButton {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 360px;
  height: 90px;
}
.сontinueButton {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 360px;
  height: 90px;
}
.warningAlert {
  padding: 65px 0 0 0;
  width: 650px;
  &Title {
    margin: 0 0 25px;
    font-weight: 500;
    font-size: 32px;
    line-height: 37px;
    text-align: center;
    letter-spacing: 0.06em;
    color: #df151c;
  }
  &SubTitle {
    margin: 0 0 65px;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 40px;
    text-align: center;
    letter-spacing: 0.04em;
    color: #df151c;
  }
  &Footer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &Button {
    width: 100%;
  }
}

.NoMatchesContent {
  display: flex;
  flex-direction: column;
  background: #ffffff;
}
.contentModal {
  flex: 1;
}
.title {
  margin: 65px 0 25px;
  font-weight: 500;
  font-size: 32px;
  line-height: 37px;
  text-align: center;
  letter-spacing: 0.06em;
  color: #000000;
}
.subTitle {
  margin: 0 0 65px;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #000000;
}
.footer {
  display: flex;
  flex: 1 1;
}
.button {
  display: flex;
  flex: 1;
}

@media (min-width: 768px) {
  .idleMonitor {
    width: 70%;
  }
  .button {
    height: 60px;
  }
}
@media (min-width: 1024px) {
  .idleMonitor {
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .idleMonitor {
    width: 600px;
  }
  .button {
    height: 90px;
  }
}
