.wizardFormStep {
  position: relative;
  flex: 1;
}
.emailCheckboxText {
  margin-top: 20px;
}
.specialEmailText {
  padding-left: 70px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.04em;
}
.navigation {
  display: flex;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
}
.navigationButton {
  position: absolute;
  bottom: 0;
}
.navigationButtonBack {
  left: 0;
}
.navigationButtonContinue {
  right: 0;
}
.mb40 {
  margin-bottom: 40px;
}
.privacyPolicyLink {
  position: absolute;
  top: 10px;
  right: 10px;
}
.privacyPolicyContent {
  width: 200px;
  height: 300px;
  background-color: red;
}
.timeSlotsList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 150px;
  // padding: 20px 0 100px;
}
@media (min-width: 768px) {
  .navigationButton {
    width: 240px;
    height: 60px;
  }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
  .navigationButton {
    width: 360px;
    height: 90px;
  }
}
