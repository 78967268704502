.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
}
.contentInner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.title {
  font-style: normal;
  font-weight: bold;
  font-size: 54px;
  line-height: 78px;
  text-align: center;
  letter-spacing: 0.3em;
  text-transform: uppercase;
  color: #ffffff;
}
