.container {
  display: flex;
  width: fit-content;
  max-width: 450px;
  flex-direction: column;
  background: #ffffff;
  min-width: 350px;
}
.footer {
  display: flex;
}
.title {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 37px;
  text-align: center;
  letter-spacing: 0.06em;
  color: #000000;
}
.description {
  padding: 0 20px;
  margin-bottom: 20px;
  * {
    margin: 0;
    color: #6e6e6e;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    color: red;
  }
}
.button {
  width: 100%;
  height: 50px;
  font-size: 16px;
  line-height: 19px;
}
