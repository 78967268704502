.navigation {
  display: flex;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
}
.navigationFilter {
  flex: 1;
}
.container {
  overflow: hidden;
  overflow-y: auto;
}
.content {
  display: flex;
  flex: 1;
}
.membersListContainer {
  display: flex;
  padding: 25px;
  justify-content: center;
  margin: -25px 0 0 -25px;
  width: calc(100% + 25px);
  flex-wrap: wrap;
  & > * {
    margin: 25px 0 0 25px;
  }
}
.addGuestButton {
  position: absolute;
  border-radius: 50%;
  font-size: 0;
  z-index: 5;
  &:after,
  &:before {
    position: absolute;
    top: 50%;
    left: 50%;
    content: '';
    background-color: #ffffff;
  }
  &:after {
    width: 26px;
    height: 4px;
    transform: translate(-50%, -50%);
  }
  &:before {
    width: 4px;
    height: 26px;
    transform: translate(-50%, -50%);
  }
}
.buttonWrapper {
  margin-right: 2em;
  margin-top: 25px;
  display: flex;
  justify-content: flex-end;
}
.confirmRemoveFromGroup {
  padding: 0 30px;
  p {
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 40px;
    letter-spacing: 0.04em;
    color: #595959;
  }
}
@media (min-width: 768px) {
  .addGuestButton {
    width: 60px;
    height: 60px;
    top: 88px;
    right: 30px;
  }
  .navigationButton {
    width: 200px;
    height: 60px;
  }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
  .navigationButton {
    width: 360px;
    height: 90px;
  }
}
