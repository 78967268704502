.HorizontalScroll {
  padding: 20px 30px 50px;
  position: relative;
  display: block;
  grid-gap: 30px 20px;
  overflow: hidden;
  &:after {
    content: "";
    height: 1px;
    background: rgba(0,0,0,0.3);
    position: absolute;
    bottom: 50px;
    width: calc(100% - 60px);
  }
  &Container {
    display: flex;
    transition: .8s ease-in-out;
  }
  &Title {
    padding: 0 30px;
    font-size: 18px;
    font-weight: 700;
  }
  &Arrow {
    max-width: 50px;
    position: absolute;
    width: 100%;
    top: calc(50% - 77px);
    opacity: 0.9;
    &Left {
      transform: scaleX(-1);
      left: 10px;
      z-index: 2;
    }
    &Right {
      right: 10px;
      z-index: 2;
    }
    &Unactive{
      opacity: .5;
    }
    img {
      width: 100%;
    }
    @media screen and (max-width: 1024px) {
      max-width: 40px;
      top: calc(50% - 68px)
    }
  }
  &Wrapper {
    &:last-child > div > div{
      border-bottom: 0;
    }
  }
}
