.categoryItem {
  display: flex;
  flex-direction: column;
  box-shadow: 0 15px 60px rgba(26, 20, 63, 0.1);
  border-radius: 2px;
  //overflow: hidden;
  cursor: pointer;
  position: relative;
}

.categoryItemPrice {
  background: red;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 5px;
  padding: 5px 10px;
  max-width: 84px;
  position: absolute;
  right: -10px;
  top: -10px;
  box-shadow: 0 0 4px 0 #0003;
}

.categoryItemCover {
  height: 190px;
  overflow: hidden;
}
.categoryItemCover img {
  display: block;
  width: 100%;
  height: 100%;
  margin: 0;
  object-fit: cover;
}
.categoryItemTitleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90px;
  overflow: hidden;
}
.categoryItemTitle {
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.08em;
  color: #000000;
}
