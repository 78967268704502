.timeSlotsListItemWrap {
}
.timeSlotsListItem {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  height: 60px;
  box-shadow: 0px 0px 30px rgba(26, 20, 63, 0.1);
  border-radius: 5px;
  cursor: pointer;
}
.timeSlotsListItemNoSlotsLeft {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #d8d8d8;
  height: 60px;
  box-shadow: 0px 0px 30px rgba(26, 20, 63, 0.1);
  border-radius: 5px;
  cursor: pointer;
}

.timeSlotsListItemTime {
  display: block;
  font-style: normal;
  font-weight: 500;
  text-align: center;
  letter-spacing: 0.08em;
  color: #000000;
}
.timeSlotsListItemLot {
  font-style: normal;
  font-weight: normal;
  text-align: center;
  letter-spacing: 0.06em;
  color: #959595;
}
.memberCardSelected {
  background: #df151c;
  div {
    color: #ffffff !important;
  }
}
@media (min-width: 768px) {
  .timeSlotsListItemWrap {
    padding: 10px;
    flex-basis: 20%;
  }
  //.timeSlotsListItem {
  //  padding: 10px 20px;
  //}
  //.memberCardActive {
  //  border: 4px solid #df151c;
  //}
  //.timeSlotsListItemNoSlotsLeft {
  //  padding: 10px 20px;
  //}
  //.timeSlotsListItemTime {
  //  font-size: 16px;
  //  line-height: 20px;
  //}
  //.timeSlotsListItemLot {
  //  font-size: 14px;
  //  line-height: 20px;
  //}
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
  .timeSlotsListItem {
    height: 95px;
  }
  //.timeSlotsListItem {
  //  // padding: 20px 30px;
  //}
  //.memberCardActive {
  //  border: 4px solid #df151c;
  //}
  //.timeSlotsListItemNoSlotsLeft {
  //  // padding: 20px 30px;
  //}

  .timeSlotsListItemTime {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 5px;
  }
  .timeSlotsListItemLot {
    font-size: 24px;
    line-height: 27px;
  }
}
