.button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  background: #ffffff;
  border: none;
  border-radius: 2px;
  touch-action: manipulation;
  &:focus {
    outline: none;
  }
  &:hover {
    cursor: pointer;
  }
}
.baseSize {
  // min-width: 340px;
  height: 110px;
}
.mediumSize {
  height: 70px;
}
.block {
  width: 100%;
}
.smallSize {
}

.defaultTheme {
}
.redTheme {
  color: #ffffff;
  background: #df151c;
  &:hover {
    background: #fd3940;
  }
  &:disabled {
    color: #000000;
    background: #f2f2f2;
  }
}
.whiteTheme {
  color: #df151c;
}
.greyTheme {
  color: #000000;
  background: #f2f2f2;
  &:hover {
    background: #fcfcfc;
  }
}
.transparentTheme {
  color: #ffffff !important;
  background: transparent !important;
  border: 1px solid rgba(255, 255, 255, 0.25);
  &:disabled {
    background: rgba(255, 255, 255, 0.25);
    cursor: not-allowed;
  }
}

@media (min-width: 768px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
