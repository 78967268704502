.container {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background-color: #FFFFFF;
}
.containerSmall {
  width: 600px;
  height: 550px;
}
.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.title {
  margin: 0;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 37px;
  text-align: center;
  letter-spacing: 0.06em;
  color: #000000;
}
.subTitle {
  margin: 0 0 30px;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #000000;
}
.form {
  &Item {
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.keyboard {
  // position: fixed;
}
.navigation {
  position: relative;
  &Button {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 240px;
    height: 60px;
    &Cancel {
      right: auto;
      left: 0;
    }
  }
}
