.content {
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #f2f2f2;
}
.flexcontainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  background-color: #f2f2f2;
}
.waiverAgreementTextContainer {
  width: calc(100% - 40px);
  margin: 0 20px 20px;
}
.waiverAgreementTextTitle {
  display: block;
  margin-bottom: 15px;
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  letter-spacing: 0.06em;
  color: #000000;
}
.waiverAgreementTextText {
  overflow: hidden;
  overflow-y: auto;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid rgb(217, 217, 217);
  box-shadow: 0px 15px 60px rgba(26, 20, 63, 0.1);
  > div {
    padding: 10px;
    color: #595959;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.04em;
  }
}
.waiverAgreementTextText2 {
  height: 75px;
  overflow: hidden;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid rgb(217, 217, 217);
  box-shadow: 0px 15px 60px rgba(26, 20, 63, 0.1);
  > div {
    padding: 10px;
    color: #595959;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.04em;
  }
}
.waiverAgreementTextText3 {
  height: 375px;
  overflow: hidden;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid rgb(217, 217, 217);
  box-shadow: 0px 15px 60px rgba(26, 20, 63, 0.1);
  > div {
    padding: 10px;
    color: #595959;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.04em;
  }
}
.waiverAgreement {
  margin: 0 20px 20px;
}
.waiverAgreementSignatureContainer {
  margin: 0 20px;
}
.waiverAgreementSignatureTitle {
  display: block;
  margin-bottom: 15px;
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  letter-spacing: 0.06em;
  color: #000000;
}
.waiverAgreementSignatureCanvas {
  display: flex;
  justify-content: center;
  align-items: center;
}
.waiverAgreementSignatureCanvasInner {
  border: 1px solid #595959;
  box-shadow: 0px 0px 30px rgba(26, 20, 63, 0.1);
  border-radius: 5px;
}
.navigation {
  display: flex;
}
.navigationFilter {
  flex: 1;
}
.minorsListModal {
  display: flex;
  flex-direction: column;
  width: 500px;
  min-height: 500px;
  background-color: #ffffff;
}
.minorsListModalContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.04em;
  color: #000000;
  p {
  }
}
.minorsListModalButton {
  width: 100%;
}
.redText {
  color: #df151c;
  text-decoration: underline;
}
.iAgreeTextbox {
  overflow: hidden;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid rgb(217, 217, 217);
  box-shadow: 0px 15px 60px rgba(26, 20, 63, 0.1);
  > div {
    padding: 10px;
    color: #595959;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.04em;
  }
}
@media (min-width: 768px) {
  .whiteButton {
    width: 240px;
    height: 60px;
  }
  .redButton {
    width: 240px;
    height: 60px;
  }
}
@media (min-width: 1024px) {
  .whiteButton {
    width: 240px;
    height: 60px;
  }
  .redButton {
    width: 240px;
    height: 60px;
  }
}
@media (min-width: 1200px) {
  .whiteButton {
    width: 360px;
    height: 90px;
  }
  .redButton {
    width: 360px;
    height: 90px;
  }
}
