.circleButtonInner {
  cursor: pointer;
}
.circleButtonInner > div {
  display: flex;
  align-items: center;
}
.addGuestButton {
  height: 55px;
  width: 55px;
  padding: 0;
  border-radius: 50%;
  border: 0;
  color: white;
  background-color: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.addGuestButton img {
  width: 62px;
  height: 62px;
}

.circleButtonInner h1 {
  font-size: 28px;
  margin-left: 0.5em;
  text-decoration: underline;
  color: #df151c;
  text-underline-offset: 5px;
}

@media (max-width: 900px) {
  .addGuestButton {
    height: 45px;
    width: 45px;
  }

  .circleButtonInner h1 {
    font-size: 25px;
  }
}
