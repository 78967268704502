.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
}
.contentInner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.title {
  margin: 0 0 15px;
  font-style: normal;
  font-weight: bold;
  font-size: 60px;
  line-height: 60px;
  text-align: center;
  letter-spacing: 0.14em;
  text-transform: uppercase;
  color: #ffffff;
}
.subTitle {
  margin: 0 0 40px;
  font-style: normal;
  font-weight: 300;
  font-size: 30px;
  line-height: 60px;
  text-align: center;
  letter-spacing: 0.07em;
  color: #ffffff;
}
.ticketIcon {
  img {
    display: block;
  }
}
