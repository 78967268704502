.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
}
.title {
  margin: 0 0 30px;
  padding: 0;
  font-style: normal;
  font-weight: 900;
  font-size: 50px;
  line-height: 55px;
  text-align: center;
  letter-spacing: 0.14em;
  text-transform: uppercase;
  color: #ffffff;
}
.userName {
  margin: 0;
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  line-height: 55px;
  text-align: center;
  letter-spacing: 0.14em;
  text-transform: uppercase;

  color: #ffffff;
}
.line {
  width: 80px;
  height: 1px;
  margin: 60px auto;
  background: #ffffff;
  opacity: 0.4;
}
.navigation {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 650px;
  justify-content: space-between;
}
.navigationItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #d2363a;
  border: none;
  box-shadow: 0px 30px 70px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.navigationItemIcon {
  display: block;
}
.navigationItemTitle {
  font-style: normal;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0.14em;
  text-transform: uppercase;
  color: #ffffff;
}

@media (min-width: 768px) {
  .line {
    margin: 30px auto;
  }
  .navigationItemIcon {
    height: 100px;
  }
  .navigationItem {
    width: 300px;
    height: 175px;
  }
  .navigationItem2 {
    width: 650px;
    height: 275px;
    margin-bottom: 25px;
  }
  .navigationItemIcon {
    display: block;
    margin-bottom: 30px;
  }
  .navigationItemTitle {
    font-size: 20px;
    line-height: 30px;
  }
}
@media (min-width: 1024px) {
  .title {
    margin: 0 0 15px;
    padding: 0;
    font-size: 30px;
    line-height: 35px;
  }
  .userName {
    font-size: 30px;
    line-height: 35px;
  }
  .line {
    margin: 25px auto;
  }
  .navigationItem {
    width: 300px;
    height: 175px;
    margin-bottom: 25px;
  }
  .navigationItem2 {
    width: 650px;
    height: 250px;
  }
}
@media (min-width: 1200px) {
  .line {
    margin: 60px auto;
  }
  .navigationItem {
    width: 620px;
    height: 200px;
  }
  .navigationItem2 {
    width: 1280px;
    height: 300px;
  }
  .navigationItemIcon {
    display: block;
    height: 100px;;
  }
  .navigationItemTitle {
    font-size: 30px;
    line-height: 45px;
  }
}
