.wizardFormField {
  display: flex;
  height: 100%;
  flex-direction: column;
  text-overflow: auto;
}
.wizardFormFieldInner {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.headerWrapper {
  margin: auto;
  margin-top: 1em;
  text-align: center;
  letter-spacing: 1px;
  & h1 {
    font-size: 28px;
    font-weight: 500;
    max-width: 550px;
  }
}
.navigationButton {
  position: fixed;
  bottom: 0;
}
.navigationButtonBack {
  left: 0;
}
.navigationButtonContinue {
  right: 0;
}
.confirmation {
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  & h1 {
    font-size: 26px;
    font-weight: 500;
    text-align: center;
  }
  & img {
    width: 120px;
    margin: 30px 0;
  }
  & p {
    text-align: center;
    color: #595959;
    max-width: 400px;
    font-weight: 500;
    letter-spacing: 1.5px;
  }
  & div {
    margin-top: 60px;
    display: grid;
    gap: 20px;
    grid-auto-flow: column;
  }
  .btn {
    width: fit-content !important;
    height: auto !important;
    padding: 10px 50px !important;
  }
}
.confirmModalBody {
  margin: 7em 0;
  text-align: center;
}
@media (min-width: 768px) {
  .container {
    flex-direction: column;
  }
  .separator {
    margin: 15px 0;
  }
  .title {
    font-size: 24px;
    line-height: 30px;
  }
  .rowMargin {
    margin-bottom: 20px;
  }

  .keyboard {
    width: 100%;
    margin-top: 500px;
  }

  .keyboard > div {
    width: 100%;
    position: fixed;
    bottom: 0;
  }
  .navigationButton {
    width: 240px;
    height: 60px;
  }
  .confirmation {
    padding: 30px;
    & h1 {
      font-size: 32px;
    }
    & img {
      width: 200px;
      margin: 60px 0;
    }
    & div {
      margin-top: 100px;
      gap: 80px;
    }
  }
}
@media (min-width: 1024px) {
  .container {
    flex-direction: row;
  }
  .separator {
    margin: 25px 0;
  }
  .title {
    font-size: 40px;
    line-height: 47px;
  }
  .rowMargin {
    margin-bottom: 30px;
  }
  .logoContent {
    margin: 5px 0px 0px 0px;
  }
}
@media (min-width: 1200px) {
  .navigationButton {
    width: 360px;
    height: 90px;
  }
}

.screenWrapper {
  background-color: #df151c;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contentWrapper {
  margin: auto;
  padding: 0 20px;
  & div > h1 {
    font-size: 36px;
    font-weight: 500;
  }
  & div > h2 {
    font-size: 28px;
    font-weight: 400;
    margin-top: 15px;
  }
}

.imageWrapper {
  display: flex;
  justify-content: center;
  margin: 3em 0;
}

.image {
  width: 15rem;
}

.contentWrapper h1 {
  color: white;
  text-align: center;
  font-size: 3rem;
  margin: 0;
}

.contentWrapper h2 {
  color: white;
  text-align: center;
  font-size: 3rem;
  margin-bottom: 2em;
  font-weight: 400;
  margin-top: 0;
}

.continueButton {
  margin: auto;
  margin-top: 7em;
  width: 70%;
  height: 5rem;
  border-radius: 5px;
  font-size: 2rem;
}

.container {
  display: flex;
  flex-direction: column;
  width: fit-content;
  width: 500px;
  height: 300px;
  background: #ffffff;
  min-width: 350px;
  padding: 15px 18px 25px 18px;
}
.footer {
  display: flex;
}
.title {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 37px;
  text-align: center;
  letter-spacing: 0.06em;
  color: #000000;
  margin: 0;
  margin-bottom: 27px;
}
.description {
  margin: auto;
  margin-bottom: 34px;
  width: 80%;
  * {
    margin: 0;
    color: #6e6e6e;
    font-size: 19px;
    line-height: 25px;
    font-weight: 500;
    text-align: center;
  }
}
.button {
  width: 50%;
  margin: auto;
  height: 50px;
  font-size: 16px;
  line-height: 19px;
}
