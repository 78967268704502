.checkboxWrap {
  min-height: 40px;
}
.checkboxLabel {
  display: block;
  padding-left: 70px;
  position: relative;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 40px;
  letter-spacing: 0.04em;
  color: #000000;
  cursor: pointer;
}
.checkmark {
  display: block;
  position: absolute;
  width: 40px;
  height: 40px;
  top: 0;
  left: 0;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  border-radius: 20px;
  content: '';
}
.checkboxLabel input:checked ~ span {
  background: #df151c url('../../assets/icons/tick.svg') no-repeat center;
  border-color: #df151c;
}
.checkboxInput {
  display: none;
}

.checkBoxWrapper {
  display: flex;
  padding-left: 0;
}
