.checkboxContainer {
  display: flex;
  align-items: center;
  min-height: 40px;
}
.checkmark {
  display: block;
  min-width: 40px;
  min-height: 40px;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  border-radius: 2px;
  margin-right: 20px;
}
.checked {
  background: #df151c url('../../assets/icons/tick.svg') no-repeat center;
  border-color: #df151c;
}

.white .checkboxLabel input:checked ~ span {
  background: #ffffff url('../../assets/icons/tick-red.svg') no-repeat center;
  border-color: #ffffff;
}
.white .checkboxLabel input:disabled ~ span {
  background-color: rgba(255, 255, 255, 0.25);
}

.white .label {
  color: #ffffff;
}

.label {
  width: 80%;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.04em;
  color: #000000;
}
.error {
  color: #df151c;
}
