.hg-theme-default {
  width: 100%;
  user-select: none;
  box-sizing: border-box;
  overflow: hidden;
  touch-action: manipulation;
}
.hg-theme-default .hg-button {
  touch-action: manipulation;
}
.hg-theme-default .hg-button span {
  pointer-events: none;
}
.hg-theme-default button.hg-button {
  border-width: 0;
  outline: 0;
  font-size: inherit;
}
.hg-theme-default {
  width: 100%;
  padding: 15px;
  border-radius: 5px;
}
.hg-theme-default .hg-button {
  display: inline-block;
}
.hg-theme-default .hg-row {
  display: flex;
  justify-content: center;
}
.hg-theme-default .hg-row > div:last-child {
  margin-right: 0;
}
.hg-theme-default .hg-row .hg-button-container {
  display: flex;
}
.hg-theme-default .hg-button {
  /*height:80px;*/
  /*width: 80px;*/
  box-sizing: border-box;
  padding: 5px;
  color: #595959;
  font-style: normal;
  font-weight: normal;
  /*font-size: 27px;*/
  line-height: 32px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  background: #ffffff;
  box-shadow: -2px -4px 15px rgba(208, 214, 224, 0.07), 0px 4px 10px rgba(0, 0, 0, 0.17),
    inset 1px 1px 0px rgba(255, 255, 255, 0.2), inset -1px -1px 0px rgba(26, 32, 46, 0.25),
    inset 10px 10px 20px rgba(255, 255, 255, 0.05);
  border-radius: 7px;
}
.hg-theme-default .hg-button.hg-activeButton {
  background: #f2f2f2;
}
.hg-theme-default.hg-layout-numeric .hg-button {
  width: 33.3%;
  height: 60px;
  align-items: center;
  display: flex;
  justify-content: center;
}
.hg-theme-default .hg-button.hg-button-numpadadd,
.hg-theme-default .hg-button.hg-button-numpadenter {
  height: 85px;
}
.hg-theme-default .hg-button.hg-button-numpad0 {
  width: 105px;
}
.hg-theme-default .hg-button-bksp {
  width: 200px;
}
.hg-theme-default .hg-button.hg-button-com {
  max-width: 85px;
}
.hg-theme-default .hg-button.hg-standardBtn.hg-button-at {
  max-width: 45px;
}
.hg-theme-default .hg-button.hg-selectedButton {
  background: rgba(5, 25, 70, 0.53);
  color: #fff;
}
.hg-theme-default .hg-button.hg-standardBtn[data-skbtn='.com'],
.hg-theme-default .hg-button.hg-standardBtn[data-skbtn='@gmail.com'],
.hg-theme-default .hg-button.hg-standardBtn[data-skbtn='@outlook.com'],
.hg-theme-default .hg-button.hg-standardBtn[data-skbtn='@icloud.com'],
.hg-theme-default .hg-button.hg-standardBtn[data-skbtn='@yahoo.com'] {
  width: auto;
  max-width: none;
}

.hg-layout-cardNumber .hg-button-bksp{
  width: 120px !important;
}

@media (min-width: 1200px) {
  .hg-layout-cardNumber .hg-button-bksp{
    width: 175px !important;
  }
}

@media (min-width: 768px) {
  .hg-theme-default .hg-row:not(:last-child) {
    margin-bottom: 9px;
  }
  .hg-theme-default .hg-row .hg-button-container,
  .hg-theme-default .hg-row .hg-button:not(:last-child) {
    margin-right: 9px;
  }
  .hg-theme-default .hg-button {
    width: 47px;
    height: 50px;
    font-size: 20px;
  }
  .hg-theme-default .hg-button-bksp {
    width: 60px;
  }
  .hg-theme-default .hg-button-enter {
    width: 100px;
  }
  .hg-theme-default .hg-button-shift {
    width: 100px;
  }
  .hg-theme-default .hg-button.hg-button-space {
    width: 400px;
  }
}
@media (min-width: 1024px) {
  .hg-theme-default .hg-row:not(:last-child) {
    margin-bottom: 15px;
  }
  .hg-theme-default .hg-row .hg-button-container,
  .hg-theme-default .hg-row .hg-button:not(:last-child) {
    margin-right: 15px;
  }
  .hg-theme-default .hg-button {
    width: 55px;
    height: 60px;
  }
  .hg-theme-default .hg-button-bksp {
    width: 150px;
  }
  .hg-theme-default .hg-button-enter {
    width: 150px;
  }
  .hg-theme-default .hg-button-shift {
    width: 150px;
  }
}
@media (min-width: 1200px) {
  .hg-theme-default .hg-button {
    height: 80px;
    width: 80px;
    font-size: 27px;
  }
  .hg-theme-default .hg-button-bksp {
    width: 200px;
  }
  .hg-theme-default .hg-button-enter {
    width: 200px;
  }
  .hg-theme-default .hg-button-shift {
    width: 200px;
  }
  .hg-theme-default .hg-button.hg-button-space {
    width: 555px;
  }
}
