.chooseParticipant {
  display: flex;
  flex-direction: column;
  background: #f2f2f2;
}
.chooseParticipantContent {
  flex: 1;
  overflow: hidden;
  overflow-y: auto;
}
.buttonWrapper {
  margin-right: 2em;
  margin-top: 25px;
  display: flex;
  justify-content: flex-end;
}
.membersListContainer {
  display: flex;
  padding: 25px;
  justify-content: center;
  margin: -25px 0 0 -25px;
  width: calc(100% + 25px);
  flex-wrap: wrap;
  & > * {
    margin: 25px 0 0 25px;
  }
}

.navigation {
  display: flex;
  &Filter {
    flex: 1;
  }
}
.columnLeft {
  float: left;
  width: 33.33%;
}
.columnCenter {
  float: center;
  width: 33.33%;
}
.columnRight {
  float: right;
  width: 33.33%;
}
.newRow {
  height: 33.33%;
}

div span.name {
  display: block;
  width: 207px;
  font-weight: bold;
  height: 100%;
  margin: 0 auto;
}

.redButton {
  &:disabled {
    filter: brightness(85%);
  }
}

@media (min-width: 768px) {
  .chooseParticipant {
    width: calc(100vw - 100px);
    height: calc(100vh - 100px);
  }
  .chooseParticipantHeader {
    height: 60px;
    h2 {
      font-size: 24px;
      line-height: 30px;
    }
  }
  .whiteButton {
    width: 180px;
    height: 60px;
  }
  .hideButton {
    display: none;
  }
  .redButton {
    float: right;
    width: 180px;
    height: 60px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .membersListContainer {
  }
}
@media (min-width: 1024px) {
  .membersListContainer {
  }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
  .chooseParticipant {
  }
  .chooseParticipantHeader {
    height: 110px;
    margin-bottom: 70px;
    h2 {
      font-size: 34px;
      line-height: 40px;
    }
  }
  .membersListContainer {
    height: 500px;
  }
  .whiteButton {
    width: 300px;
    height: 80px;
  }
  .redButton {
    width: 200px;
    height: 80px;
  }
}
