.cartItemsList {
  width: 100%;
  padding: 15px 0 15px 15px;
}
.cartItemsList > div {
  margin-bottom: 15px;
}
.cartItemsList > div:last-child {
  margin-bottom: 0;
}

.emptyCart {
  display: flex;
  flex: 1;
  height: 100%;
  background-color: #f2f2f2;
  align-items: center;
  justify-content: center;
  span {
    font-style: normal;
    font-weight: 500;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.3);
  }
}
