.navigationButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #d2363a;
  border: none;
  padding: 20px;
  border-radius: 10px;
  cursor: pointer;
}
.navigationButtonIcon {
  margin-bottom: 70px;
  img {
    display: block;
    width: 100%;
  }
}
.navigationButtonLabel {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 50px;

  white-space: pre-wrap;

  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: #FFFFFF;
}
