.kb {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background: #f2f2f2;
}
.kbWrapper {
}
.kbBoard {
  background-color: #dbdce1;
  padding: 10px 10px 0;
  display: flex;
  flex-direction: column;
  width: 1093px;
}

.kbRow {
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
}

.kbBtSquare {
  width: 85px;
  height: 80px;
  font-size: 37px;
  color: #ffffff;
  border-radius: 2px;
  background-color: #696969;
}

.kbBtBar {
  width: 150px;
  height: 80px;
}

.kbBtDark {
}

.kbBtLight {
}

.kbBtDomain {
  font-size: 20px;
}

.kbBtFinishLight {
}

.kbBtSwitch {
  font-size: 27px;
}

.kbBtQ {
  margin-left: 43px;
}

.kbBtSpace {
  width: 618px;
}

.kbBtDot {
  padding-bottom: 18px;
}

.kbInput {
  width: 673px;
  height: 78px;
  display: flex;
  align-items: center;
  align-self: center;
}
.kbInputTitle {
  font-size: 19px;
  text-transform: uppercase;
  padding-left: 32px;
  padding-right: 7px;
  color: #9b9b9b;
  white-space: nowrap;
}

.kbInputInput {
  font-size: 20px;
  width: 100%;
  padding-right: 7px;
  border: none;
}
.kbBtInput:focus {
  outline: none;
}

@media (min-width: 768px) {
  .kb {
    padding-bottom: 65px;
  }
}
@media (min-width: 1024px) {
  .kb {
    padding-bottom: 0px;
  }
}
@media (min-width: 1200px) {
}
