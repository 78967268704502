.WizardFormTypeSelector {
  display: flex;
  height: 100%;
  flex-direction: column;
}
.WizardFormTypeSelectorInner {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > div {
    position: relative;
  }
  @media screen and (max-width: 1200px) and (orientation:landscape) {
    margin-top: -100px;
  }
}
.tf {
  width: 440px;
}
.tdInvalid {
  .tfInput {
    color: rgba(223, 21, 28, 0.5);
  }
  .tfLine {
    background-color: #df151c;
  }
  .tfLabel {
    color: #df151c;
  }
}
/* Create two equal columns that floats next to each other */
.column {
  float: center;
  width: 100%;
  padding: 10px;
}
.center {
  margin: auto;
  width: 50%;
  padding: 10px;
}

/* Clear floats after the columns */
.row:after {
  content: '';
  display: table;
  clear: both;
}
.tfInput {
  display: block;
  width: 100%;
  margin-bottom: 25px;
  color: #595959;
  text-align: center;
  font-size: 30px;
  line-height: 35px;
  letter-spacing: 0.08em;
  border: none;
  background: transparent;
  @media screen  and (max-width: 1200px) and (orientation:landscape){
    margin-bottom: 10px;
  }
}
.tfInput:focus {
  outline: none;
}
.tfLine {
  height: 1px;
  width: 100%;
  background: #d9d9d9;
}
.tfLabel {
  margin: 15px 0 0;
  color: #595959;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
.errorMessage {
  display: block;
  margin-top: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.04em;
  color: rgba(223, 21, 28, 0.7);
}
.required {
  margin-left: 5px;
  color: #df151c;
}

.listItem {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 25px;
  color: #595959;
  text-align: center;
  font-size: 30px;
  line-height: 35px;
  letter-spacing: 0.08em;
  border: none;
  background: transparent;
}
.titleWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.title {
  margin: 0;
  font-weight: bold;
  font-size: 48px;
  line-height: 56px;
  text-align: center;
  letter-spacing: 0.06em;
  color: #000000;
  margin-bottom: 25px;
  @media screen and (max-width: 1200px) and (orientation:landscape) {
    margin-bottom: 5px;
    font-size: 32px;
  }
}
.required {
  margin-left: 5px;
  color: #df151c;
}
.separator {
  display: block;
  margin: 50px 0;
  width: 80px;
  height: 1px;
}
.errorMessage {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.04em;
  color: rgba(223, 21, 28, 0.7);
}
.navigationButton {
  width: 10%;
  height: 50px;
  bottom: 0;
  margin-top: 20px;
}
.navigationButtonBack {
  left: 0;
}
.showSuggestions {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    margin: 15px 0;
    font-size: 25px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.06em;
    color: #595959;
  }
}
