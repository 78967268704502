.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 0 25px;
}
.title {
  font-size: 50px;
  font-weight: 900;
  font-style: normal;
  line-height: 55px;
  margin: 0 0 30px;
  padding: 0;
  text-align: center;
  letter-spacing: .14em;
  text-transform: uppercase;
  color: #fff;
}
.userName {
  margin: 0;
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  line-height: 55px;
  text-align: center;
  letter-spacing: 0.14em;
  text-transform: uppercase;
  color: #ffffff;
}
.line {
  width: 80px;
  height: 1px;
  margin: 60px auto;
  background: #ffffff;
  opacity: 0.4;
}
.navigation {
  width: 1300px;
  display: flex;
  flex-direction: column;
  grid-gap: 25px;
  max-width: 100%;
  @media screen and (min-width: 600px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

.navigationItem {
  box-shadow: 0 0 120px 0 #0000002b;
  :global(.navigation-button__icon) {
    margin-bottom: 30px;
    img {
      width: auto;
      height: 130px;
    }
  }
  :global(.navigation-button__label) {
    font-size: 20px;
    line-height: 30px;
  }
}

.navigationItem2 {
  box-shadow: 0 0 120px 0 #0000002b;
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 3;
  :global(.navigation-button__icon) {
    margin-bottom: 30px;
    img {
      width: auto;
      height: 130px;
    }
  }
  :global(.navigation-button__label) {
    font-size: 20px;
    line-height: 30px;
  }
}

@media (min-width: 768px) {
  .line {
    margin: 30px auto;
  }
}
@media (min-width: 1024px) {
  .title {
    margin: 0 0 15px;
    padding: 0;
    font-size: 30px;
    line-height: 35px;
  }
  .userName {
    font-size: 30px;
    line-height: 35px;
  }
  .line {
    margin: 25px auto;
  }
}
@media (min-width: 1200px) {
  .line {
    margin: 60px auto;
  }
}
