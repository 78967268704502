.panel {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.panelButton {
  height: 60px;
  width: 200px;
}
.panelBox {
  position: absolute;
  width: 200px;
  &Left {
    left: 0;
    bottom: 100%;
  }
  &Right {
    right: 0;
    bottom: 100%;
  }
}
.panelContent {
  display: flex;
  width: auto;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translate(-50%);
}
