.container {
  display: flex;
  min-width: 500px;
  flex-direction: column;
  background: #ffffff;
  border-radius: 10px;
  padding: 0 1.5rem 0.5rem 1.5rem;
  color: #959595;
}
.title {
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 37px;
  text-align: center;
  letter-spacing: 0.06em;
  color: #595959;
}
.list {
  padding: 20px;
  padding-bottom: 0px;
  p {
    margin: 0 0 20px;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 40px;
    text-align: center;
    letter-spacing: 0.04em;
    white-space: pre-wrap;
    color: #000000;
  }
}
.listItem {
  display: flex;
  justify-content: space-between;
}

.listItem h1 {
  font-size: 1.25rem;
  margin: 0 0 1em 0;
}

.button {
  width: 100%;
  height: 60px;
  margin: auto;
  margin-bottom: 1em;
  font-size: 16px;
  line-height: 19px;
  border-radius: 4px;
}
