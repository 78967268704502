.buttonIcon {
  display: flex;
  height: 50px;
  padding: 0;
  align-items: center;
  border: none;
  background: transparent;
}
.iconWrapper {
  display: flex;
}
.titleWrapper {
  display: flex;
  margin: 0 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  text-transform: uppercase;
}
