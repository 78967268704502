html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body {
  height: 100%;
  overscroll-behavior-x: none;
}

body {
  font-family: 'Roboto';
}

#root {
  display: flex;
  min-height: 100%;
  min-width: 100%;
}

input:focus {
  outline: none;
}
button:focus {
  outline: none;
}
