@font-face {
  font-family: 'Roboto';
  src: url('../../assets/fonts/roboto-thinitalic-webfont.woff2') format('woff2'),
    url('../../assets/fonts/roboto-thinitalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../../assets/fonts/roboto-thin-webfont.woff2') format('woff2'),
    url('../../assets/fonts/roboto-thin-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../../assets/fonts/roboto-regular-webfont.woff2') format('woff2'),
    url('../../assets/fonts/roboto-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../../assets/fonts/roboto-mediumitalic-webfont.woff2') format('woff2'),
    url('../../assets/fonts/roboto-mediumitalic-webfont.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('../../assets/fonts/roboto-medium-webfont.woff2') format('woff2'),
    url('../../assets/fonts/roboto-medium-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../../assets/fonts/roboto-lightitalic-webfont.woff2') format('woff2'),
    url('../../assets/fonts/roboto-lightitalic-webfont.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('../../assets/fonts/roboto-light-webfont.woff2') format('woff2'),
    url('../../assets/fonts/roboto-light-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../../assets/fonts/roboto-bolditalic-webfont.woff2') format('woff2'),
    url('../../assets/fonts/roboto-bolditalic-webfont.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Password';
  src: url('../../assets/fonts/password.woff2') format('woff2'),
  url('../../assets/fonts/password.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
