.wizardFormField {
  display: flex;
  height: 100%;
  flex-direction: column;
}
.wizardFormFieldInner {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.navigationButton {
  position: absolute;
  bottom: 0;
}
.navigationButtonBack {
  left: 0;
}
.navigationButtonContinue {
  right: 0;
}
.confirmation {
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  & h1 {
    font-size: 26px;
    font-weight: 500;
    text-align: center;
  }
  & .imageWrapper {
    width: 40%;
    height: 20rem;
    margin: 0 0 30px 0;
  }
  & img {
    height: 100%;
    width: 100%;
  }
  & p {
    text-align: center;
    color: #595959;
    max-width: 400px;
    font-weight: 500;
    letter-spacing: 1.5px;
  }
  & div {
    margin-top: 60px;
    display: grid;
    gap: 20px;
    grid-auto-flow: column;
  }
  .btn {
    width: fit-content !important;
    height: auto !important;
    padding: 10px 50px !important;
    padding: 15px 100px !important;
    box-shadow: 0px 3px 6px #00000029;
  }
}
@media (min-width: 768px) {
  .container {
    flex-direction: column;
  }
  .separator {
    margin: 15px 0;
  }
  .title {
    font-size: 24px;
    line-height: 30px;
  }
  .rowMargin {
    margin-bottom: 20px;
  }
  .navigationButton {
    width: 240px;
    height: 60px;
  }
  .confirmation {
    padding: 30px;
    & h1 {
      font-size: 32px;
    }
    & img {
      width: 200px;
      margin: 60px 0;
    }
    & p {
      color: #595959;
      line-height: 25px;
    }
    & div {
      margin-top: 100px;
      gap: 80px;
    }
  }
}
@media (min-width: 1024px) {
  .container {
    flex-direction: row;
  }
  .separator {
    margin: 25px 0;
  }
  .title {
    font-size: 40px;
    line-height: 47px;
  }
  .rowMargin {
    margin-bottom: 30px;
  }
  .logoContent {
    margin: 5px 0px 0px 0px;
  }
}
@media (min-width: 1200px) {
  .navigationButton {
    width: 360px;
    height: 90px;
  }
}
