.button {
  display: block;
  width: 90px;
  height: 90px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 23px;
  color: #000000;
  background-color: #ffffff;
  padding: 0;
  border: 1px solid #dedede;
  cursor: pointer;
  border-radius: 50%;
  touch-action: manipulation;
  &:active {
    background-color: #f2f2f2;
    border-color: #f2f2f2;
  }
  &:focus {
    outline: none;
  }
  img {
    display: inline-block;
  }
}

@media (min-width: 1200px) {
  .button {
    width: 120px;
    height: 120px;
  }
}
