.Modal {
  position: absolute;
  //top: 0;
  //left: 0;
  //right: 0;
  //bottom: 0;
  // position: absolute;
  //display: flex;
  //justify-content: center;
  //align-items: center;
  //top: 0px;
  //left: 0px;
  //right: 0px;
  //bottom: 0px;
  // background: rgba(0, 0, 0, 0.4);
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  //position: fixed;
  //top: 0;
  //left: 0;
  //right: 0;
  //bottom: 0;
  z-index: 100;
  background: rgba(0, 0, 0, 0.4);
}





//.modal {
//  display: flex;
//  position: fixed;
//  justify-content: center;
//  align-items: center;
//  top: 0;
//  left: 0;
//  right: 0;
//  bottom: 0;
//  background: rgba(0, 0, 0, 0.4);
//  z-index: 100;
//}
//.modalContent {
//  background-color: #ffffff;
//}
//.backButton {
//  position: absolute;
//  bottom: 0;
//  left: 0;
//}
//@media (min-width: 768px) {
//  .backButton {
//    width: 240px;
//    height: 60px !important;
//  }
//}
//@media (min-width: 1024px) {
//  .backButton {
//    width: 240px;
//    height: 60px !important;
//  }
//}
//@media (min-width: 1200px) {
//  .backButton {
//    width: 360px;
//    height: 90px !important;
//  }
//}
